import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import buttonLeft from "../../assets/images/nav/button-left.png";
import buttonRight from "../../assets/images/nav/button-right.png";
import { useAuth } from "context/AuthContext";
import usePointsStore from "store";
import { BiRightArrow } from "react-icons/bi";
import { motion } from "framer-motion";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const MAX_STEPS = 22;

let MOUSE_MOVE_TIMEOUT = null;

function Navigation() {
	const { signOut } = useAuth();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const { isNavigationVisible } = usePointsStore();

	const [autoPaginateProgress, setAutoPaginateProgress] = useState(0);

	const [currentStep, setCurrentStep] = useState(0);

	useEffect(() => {
		if (params.id) {
			setCurrentStep(Number.parseInt(params.id));
		}
	}, [params]);

	useEffect(() => {
		// const mouveMoveListener = document.addEventListener("mousemove", () => {
		// 	clearTimeout(MOUSE_MOVE_TIMEOUT);
		// 	setAutoPaginateProgress(0);
		// 	MOUSE_MOVE_TIMEOUT = setTimeout(() => {
		// 		setAutoPaginateProgress(1);
		// 	}, 5000);
		// }
	}, []);

	const goToStep = useCallback(
		(index: number) => {
			if (location.pathname === "/onboarding") {
				if (index > 0) {
					navigate("/passo/1", {
						state: {
							direction: "next",
						},
					});
					return;
				} else {
					signOut();
					navigate("/");
					return;
				}
			}

			if (!params.id) return;

			const currentStep = Number.parseInt(params.id);
			const nextStep = currentStep + index;

			if (nextStep === 0) {
				navigate("/onboarding", {
					state: {
						direction: "previous",
					},
				});
				return;
			}

			if (nextStep >= 1 && nextStep <= MAX_STEPS) {
				navigate(`/passo/${nextStep}`, {
					state: {
						direction: index > 0 ? "next" : "previous",
					},
				});
			}
		},
		[params, navigate]
	);

	if (!isNavigationVisible) return null;

	return (
		<div className="absolute z-20 bottom-0 left-0 right-0 flex justify-between">
			<div
				onClick={() => goToStep(-1)}
				className="absolute bottom-0 left-0 bg-contain bg-no-repeat bg-bottom w-[444px] h-[444px] cursor-pointer"
				style={{
					backgroundImage: `url(${buttonLeft})`,
				}}
			>
				<motion.div
					initial={{
						scale: 1,
					}}
					animate={{
						scale: [1, 1.1, 1],
					}}
					transition={{
						duration: 1,
					}}
					className="absolute flex items-center justify-center left-10 bottom-8 w-[88px] h-[88px] shadow bg-white rounded-full"
				>
					<FaArrowLeft size={48} />
				</motion.div>
			</div>
			{currentStep < MAX_STEPS ? (
				<div
					onClick={() => goToStep(1)}
					className="absolute bottom-0 right-0 bg-contain bg-no-repeat bg-bottom w-[444px] h-[444px] cursor-pointer"
					style={{
						backgroundImage: `url(${buttonRight})`,
					}}
				>
					<motion.div
						initial={{
							scale: 1,
						}}
						animate={{
							scale: [1, 1.1, 1],
						}}
						transition={{
							duration: 1,
							repeat: Infinity,
						}}
						className="absolute flex items-center justify-center right-10 bottom-8 w-[88px] h-[88px] shadow bg-white rounded-full"
					>
						<FaArrowRight size={48} />
					</motion.div>
				</div>
			) : null}
		</div>
	);
}

export default Navigation;
