import { UIElementProps } from "types/prop-types";

// import logo from "assets/images/logo-jornada.svg";
const logo = require("assets/images/logo-jornada.png");

const MIN_SIZE = 256;

type LogoJornadaProps = {
	size?: number;
} & UIElementProps;

export default function LogoJornada({
	name,
	size = MIN_SIZE,
	style,
	className,
}: LogoJornadaProps) {
	return (
		<div
			className={`relative bg-white rounded-full overflow-hidden ${className || ""}`}
			style={{
				...style,
			}}
		>
			<img
				src={logo}
				alt="Jornada COOP"
				className={`pointer-none absolute w-full h-full object-contain object-center p-[16%]`}
			/>
		</div>
	);
}
