import { createPortal } from "react-dom";
import { motion } from "framer-motion";
import {
	AiFillCloseCircle,
	AiOutlineClose,
	AiOutlineCloseCircle,
} from "react-icons/ai";
import { useState } from "react";
import Eliza from "components/ui/Eliza";

type AchievementModalProps = {
	positive?: boolean;
	logo?: string;
	title?: string;
	points?: number;
};

function AchievementModal({
	positive,
	logo,
	title,
	points,
}: AchievementModalProps) {
	const [isOpen, setIsOpen] = useState(true);

	function handleClose() {
		setIsOpen(false);
	}

	if (!isOpen) return null;

	return createPortal(
		<div
			onClick={() => handleClose()}
			className="fixed top-0 left-0 w-full h-full bg-blue-dark bg-opacity-90 z-40 overflow-hidden flex items-center justify-center"
		>
			<motion.div
				initial={{ scale: 0 }}
				animate={{ scale: 1 }}
				transition={{ duration: 0.3, ease: "backInOut" }}
				className="relative top-16 w-[800px] h-[800px] bg-white rounded-full shadow-lg p-10"
			>
				<div className="text-center pt-32">
					{logo ? (
						<div className="absolute -top-20 left-0 right-0 mx-auto">
							<img
								src={logo}
								alt="Selo"
								className="w-[280px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
							/>
						</div>
					) : null}
					<h2
						className={`text-6xl font-bold ${
							positive ? "text-green-500" : "text-red-500"
						}`}
					>
						{positive ? "Parabéns!" : "Que pena!"}
					</h2>
					{title ? <p className="text-3xl mt-4 px-10">{title}</p> : null}
					<div
						className={`
            ${
							positive
								? ""
								: " absolute bottom-0 left-0 right-0 mx-auto p-8 w-full h-full rounded-full overflow-hidden"
						}
            `}
					>
						<Eliza
							name={positive ? "shipHappy" : "sadBig"}
							className={`
              ${
								positive
									? "w-[400px] mx-auto mt-4 animate animate-fly"
									: "w-[300px] mx-auto relative top-[55%] animate animate-wiggle"
							}
              `}
						/>
					</div>
					{points ? (
						<p className="text-3xl flex flex-col items-center leading-none gap-3 mt-4">
							<span>Você ganhou</span>
							<b className="text-5xl border border-dashed py-3 px-6 pt-4 rounded-full text-brand-purple">
								{points} pontos
							</b>
							{/* <span>de cooperativismo!</span> */}
						</p>
					) : null}
				</div>
				<button
					type="button"
					className="absolute -top-0 -right-0 p-4 text-white font-bold text-2xl rounded-full mt-8 border-4 border-white"
				>
					<AiOutlineClose size={52} />
				</button>
			</motion.div>
		</div>,
		document.getElementById("root") || document.body
	);
}

export default AchievementModal;
