import Anuario from "pages/Anuario";
import CapacitaCoop from "pages/CapacitaCoop";
import CapacitaCoopGame from "pages/CapacitaCoop/game";
import Desempenho from "pages/Desempenho";
import DesempenhoGame from "pages/Desempenho/game";
import ESG from "pages/ESG";
import EsgGame from "pages/ESG/game";
import Finish from "pages/Finish";
import Governanca from "pages/Governanca";
import GovernancaGame from "pages/Governanca/game";
import Identidade from "pages/Identidade";
import InovaCoop from "pages/InovaCoop";
import InovaCoopGame from "pages/InovaCoop/game";
import NegociosCoop from "pages/NegociosCoop";
import SomosCoop from "pages/SomosCoop";
import SomosCoopGame from "pages/SomosCoop/game";
import SouCoop from "pages/SouCoop";
import { useParams } from "react-router-dom";

function Step() {
	const params = useParams();

	if (!params.id) return null;

	const step = parseInt(params.id);

	const steps = [
		null,
		SomosCoopGame,
		SomosCoop,
		null,
		SouCoop,
		null,
		Anuario,
		null,
		DesempenhoGame,
		Desempenho,
		null,
		GovernancaGame,
		Governanca,
		null,
		// EsgGame,
		// ESG,
		// null,
		InovaCoopGame,
		InovaCoop,
		null,
		CapacitaCoopGame,
		CapacitaCoop,
		null,
		NegociosCoop,
		null,
		Finish,
	];

	const Component = steps[step];
	if (!Component) return null;

	return <Component />;
}

export default Step;
