import bg from "assets/images/bg/bg-somos-coop.jpg";
import section from "assets/images/trophy.svg";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/logo-avalia-desempenho.svg";
import usePointsStore from "store";
import { useEffect } from "react";
import DialogBubble from "components/dialog/dialog-bubble";
import AchievementModal from "components/modal/AchievementModal";
import { useCoop } from "context/CoopContext";
import { formatarFaturamento } from "utils/helpers";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function Desempenho() {
	const { setDesempenhoCoop } = usePointsStore();
	const { coopInfo, desempenho } = useCoop();

	useEffect(() => {
		if (desempenho) {
			setDesempenhoCoop(true);
			console.log(desempenho);
		}
	}, [desempenho, setDesempenhoCoop]);

	function getIndicadorLabel(ramo: string) {
		switch (ramo) {
			case "CRÉDITO":
				return "em ativos totais";
			case "SAÚDE":
				return "em ingressos";
			default:
				return "em faturamento";
		}
	}

	function getIndicadorMessage() {
		switch (coopInfo?.ramo) {
			case "CRÉDITO":
				return (
					<b>
						Os ativos totais da coop
						<br />
						{coopInfo?.nome_fantasia}
						<br />
						foram da ordem de R$ {formatarFaturamento(desempenho?.indicador)}.
						Uau!
					</b>
				);
			case "SAÚDE":
				return (
					<b>
						Os ingressos da coop
						<br />
						{coopInfo?.nome_fantasia}
						<br />
						foram da ordem de R$ {formatarFaturamento(desempenho?.indicador)}.
						Uau!
					</b>
				);
			default:
				return (
					<b>
						O faturamento da coop
						<br />
						{coopInfo?.nome_fantasia}
						<br />
						foi da ordem de R$ {formatarFaturamento(desempenho?.indicador)}.
						Uau!
					</b>
				);
		}
	}

	return (
		<>
			<AchievementModal
				positive={desempenho}
				points={desempenho ? 1000 : 0}
				logo={logo}
				title={
					desempenho
						? `A sua coop participa do Avalia Coop - Sistema Desempenho!`
						: `A sua coop não participa do Avalia Coop - Sistema Desempenho!`
				}
			/>
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute top-32 right-20">
					<img
						src={logo}
						alt="Selo"
						className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
					/>
				</div>

				<div className="absolute z-20 top-[310px] left-[90px]">
					<DialogBubble model="blue-top-left" className="w-[500px] h-[250px]">
						<div className="text-2xl px-3">
							{desempenho ? (
								getIndicadorMessage()
							) : (
								<b>
									Poxa! A {coopInfo?.nome_fantasia} não está no Sistema
									Desempenho. Isso vai dificultar um pouco a nossa Jornada.
								</b>
							)}
						</div>
					</DialogBubble>
				</div>

				<Eliza
					name="home"
					className="z-20 absolute left-[-50px] -bottom-[40px] w-[700px] -scale-x-[100%]"
				/>

				<img
					src={section}
					alt="Somos Coop"
					className="z-20 w-[570px] animate-in slide-in-from-right-5 absolute right-[60px] bottom-[0px] object-contain object-center"
				/>

				{desempenho ? (
					<div className="flex flex-col gap-16 justify-center items-center absolute bg-white bg-opacity-85 backdrop-blur top-[100px] bottom-0 left-0 right-0 m-auto w-[800px] h-[700px] border-b-8 border-b-white py-16 px-10 rounded-xl shadow-glow-blue">
						<h1 className="text-5xl font-bold text-center">
							Confira seu desempenho <br />
							entre as cooperativas do ramo{" "}
							<b className="text-pink-600">{coopInfo?.ramo}</b> no Brasil:
						</h1>
						<h1 className="text-9xl font-bold text-center text-yellow-500">
							{desempenho?.indicador_ranking}
						</h1>
						<div className="text-5xl font-bold text-center text-black animate animate-wiggle">
							<p className="text-7xl text-brand-dark-purple">
								R$ {formatarFaturamento(desempenho?.indicador)}
							</p>{" "}
							{getIndicadorLabel(coopInfo?.ramo)}
						</div>
					</div>
				) : null}

				{!desempenho ? (
					<div className="flex flex-col gap-16 justify-center items-center absolute bg-white bg-opacity-85 backdrop-blur top-[100px] bottom-0 left-0 right-0 m-auto w-[800px] h-[700px] border-b-8 border-b-white py-16 px-10 rounded-xl shadow-glow-blue">
						<AiOutlineCloseCircle className="text-9xl text-red-500" />
						<h1 className="text-4xl px-16 font-bold text-center">
							Você sabia que o Desempenho possibilita o acompanhamento dos
							resultados das coops em relação aos seus indicadores econômicos,
							contábeis e socioeconômicos?
						</h1>

						<div className="font-bold text-center text-black animate animate-wiggle">
							<p className="text-3xl text-brand-dark-purple">
								Procure a Organização Estadual
								<br />
								do Sistema OCB do seu estado e saiba mais!
							</p>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
}
