import { motion } from "framer-motion";
import type { UIElementProps } from "types/prop-types";

function AnimatedText({ children, delay }: UIElementProps) {
	return (
		<motion.div
			initial={{
				translateX: -50,
				opacity: 0,
			}}
			animate={{
				translateX: 0,
				opacity: 1,
			}}
			transition={{ duration: 0.3, ease: "easeOut", delay }}
		>
			{children}
		</motion.div>
	);
}

export default AnimatedText;
