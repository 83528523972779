type DialogBubbleProps = {
	children: React.ReactNode;
	className?: string;
	model?:
		| "default"
		| "pink-up"
		| "pink-left"
		| "pink-down"
		| "pink-top-left"
		| "blue-top-left"
		| "top-left"
		| "left";
};

function DialogBubble(props: DialogBubbleProps) {
	const { children, className, model = "default" } = props;
	return (
		<div className={`dialog-bubble relative ${className}`}>
			{model === "default" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 860 384"
						preserveAspectRatio="none"
					>
						<path
							fillRule="evenodd"
							fillOpacity="0.702"
							fill="rgb(0, 0, 0)"
							d="M37.1000,4.1000 L860.1000,-0.000 L829.1000,384.000 L104.1000,376.1000 L79.000,263.1000 L-0.000,241.1000 L72.000,208.1000 L37.1000,4.1000 Z"
						/>
					</svg>
					<div className="absolute z-10 top-0 left-10 w-full h-full flex items-center justify-center">
						<h1 className="text-6xl font-cond text-white font-bold">
							{children}
						</h1>
					</div>
				</>
			) : null}

			{model === "pink-left" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 860 384"
						preserveAspectRatio="none"
					>
						<path
							fillRule="evenodd"
							fill="#c34b7c"
							stroke="#FFFFFF"
							strokeWidth={7}
							d="M37.1000,4.1000 L860.1000,-0.000 L829.1000,384.000 L104.1000,376.1000 L79.000,263.1000 L-0.000,241.1000 L72.000,208.1000 L37.1000,4.1000 Z"
						/>
					</svg>
					<div className="absolute z-10 top-0 left-10 w-full h-full flex items-center justify-center">
						<h1 className="text-5xl text-white font-bold">{children}</h1>
					</div>
				</>
			) : null}

			{model === "pink-up" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 1067 315"
						preserveAspectRatio="none"
					>
						<path
							fillRule="evenodd"
							fill="rgb(195, 75, 124)"
							d="M0.000,10.000 L977.1000,0.000 L946.000,198.000 L1067.000,298.1000 L935.1000,256.000 L922.000,315.000 L18.1000,285.1000 L0.000,10.000 Z"
						/>
					</svg>
					<div className="absolute z-10 top-0 left-16 right-32 h-full flex items-center justify-center">
						<h1 className="text-5xl text-white font-bold">{children}</h1>
					</div>
				</>
			) : null}
			{model === "pink-down" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 1054 258"
						preserveAspectRatio="none"
					>
						<path
							fillRule="evenodd"
							fill="rgb(195, 75, 124)"
							d="M-0.000,22.1000 L995.1000,0.000 L987.1000,105.000 L1054.000,90.000 L993.000,150.000 L981.1000,249.000 L42.000,257.1000 L-0.000,22.1000 Z"
						/>
					</svg>
					<div className="absolute z-10 top-2 left-20 right-20 h-full flex items-center justify-center">
						<h1 className="text-5xl text-white font-bold">{children}</h1>
					</div>
				</>
			) : null}
			{model === "pink-top-left" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 1920 714.8"
						preserveAspectRatio="none"
					>
						<path
							fill="#c34b7c"
							stroke="#FFFFFF"
							strokeWidth={10}
							d="M0,19L1920,0l-44,534-1301.9,9.8-79.9,168-75.4-169.3H33.7S0,19,0,19Z"
						/>
					</svg>
					<div className="absolute z-10 top-2 left-2 right-2 bottom-[23%] flex items-center justify-center">
						<div className="text-5xl text-white text-center">{children}</div>
					</div>
				</>
			) : null}
			{model === "top-left" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 1920 714.8"
						preserveAspectRatio="none"
					>
						<path
							fill="#F4F4F4"
							d="M0,19L1920,0l-44,534-1301.9,9.8-79.9,168-75.4-169.3H33.7S0,19,0,19Z"
						/>
					</svg>
					<div className="absolute z-10 top-2 left-2 right-2 bottom-[23%] flex items-center justify-center">
						<div className="text-5xl text-brand-dark-purple text-center">
							{children}
						</div>
					</div>
				</>
			) : null}
			{model === "blue-top-left" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 1920 714.8"
						preserveAspectRatio="none"
					>
						<path
							fill="#6D22F9"
							stroke="#FFFFFF"
							strokeWidth={10}
							d="M0,19L1920,0l-44,534-1301.9,9.8-79.9,168-75.4-169.3H33.7S0,19,0,19Z"
						/>
					</svg>
					<div className="absolute z-10 top-2 left-2 right-2 bottom-[23%] flex items-center justify-center">
						<div className="text-5xl text-white text-center">{children}</div>
					</div>
				</>
			) : null}
			{model === "left" ? (
				<>
					<svg
						width="100%"
						height="100%"
						viewBox="0 0 831 459"
						preserveAspectRatio="none"
					>
						<path
							d="M830.461 6.89609e-05L31.7862 32.1208L58.4605 281.5L0.460583 313L63.9232 326.09L80.4607 459L830.461 437L830.461 6.89609e-05Z"
							fill="#F4F4F4"
						/>
					</svg>

					<div className="absolute z-10 top-0 left-24 pl-4 right-14 h-full flex items-center justify-center rotate-[-2deg]">
						<div className="text-[48px] leading-none text-brand-dark-purple font-bold text-center">
							{children}
						</div>
					</div>
				</>
			) : null}
		</div>
	);
}

export default DialogBubble;
