import { useState, useEffect } from "react";

type Props = {
	finalName: string;
	wordLength: number;
	waitTime: number;
	className?: string;
};

const TIME_PER_CHARACTER = 200;

const LotteryDisplay: React.FC<Props> = ({
	finalName,
	wordLength,
	waitTime,
	className,
}) => {
	const [displayedWord, setDisplayedWord] = useState<string>("");

	useEffect(() => {
		setDisplayedWord(generateRandomWord(wordLength));
	}, []);

	useEffect(() => {
		const scrambleInterval = setInterval(() => {
			setDisplayedWord(generateRandomWord(wordLength));
		}, 50);

		const revealTimeout = setTimeout(() => {
			clearInterval(scrambleInterval);
			setDisplayedWord(finalName);
		}, waitTime);

		return () => {
			clearInterval(scrambleInterval);
			clearTimeout(revealTimeout);
		};
	}, [finalName, waitTime, wordLength]);

	const generateRandomWord = (length: number): string => {
		let word = "";
		for (let i = 0; i < length; i++) {
			word += getRandomChar();
		}
		return word;
	};

	const getRandomChar = (): string => {
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞ";
		return chars[Math.floor(Math.random() * chars.length)];
	};

	return <div className={`${className}`}>{displayedWord}</div>;
};

export default LotteryDisplay;
