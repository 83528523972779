import { FaArrowLeft, FaHome } from "react-icons/fa";
import { UIElementProps } from "types/prop-types";

type BackProps = {
	isHome?: boolean;
	className?: string;
} & UIElementProps;

function Back({ isHome, className, onClick }: BackProps) {
	return (
		<div
			className={`bg-white w-[72px] h-[72px] shadow rounded-full flex items-center justify-center ${className}`}
			onClick={onClick}
		>
			{isHome ? (
				<FaHome className="w-full h-full p-[20%]" />
			) : (
				<FaArrowLeft className="w-full h-full p-[20%]" />
			)}
		</div>
	);
}

export default Back;
