import bg from "assets/images/bg/bg-capacita-coop.jpg";
import section from "assets/images/section/capacita-coop.png";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/logo-capacita-coop.svg";
import usePointsStore from "store";
import { useEffect, useState } from "react";
import DialogBubble from "components/dialog/dialog-bubble";
import { BiCheckCircle, BiUser } from "react-icons/bi";
import AchievementModal from "components/modal/AchievementModal";
import { useCoop } from "context/CoopContext";

export default function CapacitaCoop() {
	const { setCapacitaCoop } = usePointsStore();
	const { capacitaCoop } = useCoop();

	const [cadastros, setCadastros] = useState(0);
	const [matriculas, setMatriculas] = useState(0);

	useEffect(() => {
		if (capacitaCoop) {
			setCadastros(capacitaCoop.usuarios);
			setMatriculas(capacitaCoop.matriculas);
			setCapacitaCoop(true);
		}
	}, [setCapacitaCoop]);

	return (
		<>
			<AchievementModal
				positive={capacitaCoop}
				points={capacitaCoop ? 1000 : 0}
				logo={logo}
				title={
					capacitaCoop
						? `A sua coop possui usuários cadastrados na Capacitacoop!`
						: `A sua coop ainda não possui usuários cadastrados na Capacitacoop!`
				}
			/>
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute top-32 right-20">
					<img
						src={logo}
						alt="Selo"
						className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
					/>
				</div>

				<div className="absolute z-20 top-[180px] left-[140px]">
					<DialogBubble model="blue-top-left" className="w-[470px] h-[250px]">
						<div className="text-2xl px-3">
							<b>
								Você sabia que já temos mais de 200 cursos disponíveis na
								plataforma?
							</b>
							<p className="mt-2 underline">Acesse: capacita.coop.br</p>
						</div>
					</DialogBubble>
				</div>

				<Eliza
					name="stars"
					className="absolute left-[100px] -bottom-[270px] w-[380px]"
				/>

				<div className="flex flex-col gap-14 justify-center items-center absolute bg-white bg-opacity-85 backdrop-blur top-[200px] left-0 right-0 mx-auto w-[800px] h-[800px]  border-b-8 border-b-white px-10 rounded-xl shadow-glow-blue">
					<h1 className="text-3xl uppercase text-center font-bold">
						A Capacitacoop oferece
						<br />
						cursos e treinamentos adaptados
						<br />
						às necessidades das cooperativas.
					</h1>

					<div className="mx-auto flex items-center text-center">
						<div className="governanca flex flex-col items-center">
							<BiUser size={64} className="mb-3" />
							<div className="text-white">
								<h1 className="text-3xl font-bold text-brand-purple">
									USUÁRIOS CADASTRADOS
								</h1>
								<p className="text-9xl mt-5 text-blue-dark font-bold text-orange-600">
									{cadastros}
								</p>
							</div>
						</div>

						<div className="gestao flex flex-col items-center">
							<BiCheckCircle size={64} className="mb-3" />
							<div className="text-white">
								<h1 className="text-3xl font-bold text-brand-purple">
									MATRÍCULAS FINALIZADAS
								</h1>
								<p className="text-9xl mt-5 text-blue-dark font-bold text-orange-600">
									{matriculas}
								</p>
							</div>
						</div>
					</div>

					<h1 className="text-2xl uppercase text-center">
						Com opções de cursos online, os membros
						<br />
						podem aprender no seu próprio ritmo e horário, facilitando a
						conciliação com outras atividades.
					</h1>
				</div>

				<img
					src={section}
					alt="Somos Coop"
					className="w-[350px] animate-in slide-in-from-right-5 absolute right-36 bottom-0 object-contain object-center"
				/>
			</div>
		</>
	);
}
