import bg from "assets/images/bg/bg-finish.jpg";
import Eliza from "components/ui/Eliza";

import qrcode from "assets/images/qrcode.png";

import logoJornada from "assets/images/logo-jornada.png";

import logoSomosCoop from "assets/images/selo.svg";
import logoSouCoop from "assets/images/logo-sou-coop.svg";
import logoDesempenho from "assets/images/logo-avalia-desempenho.svg";
import logoIdentidade from "assets/images/logo-avalia-identidade.svg";
import logoGovernanca from "assets/images/logo-avalia-governanca.svg";
import logoEsg from "assets/images/logo-avalia-esg.svg";
import logoInovaCoop from "assets/images/logo-inova-coop.svg";
import logoCapacitaCoop from "assets/images/logo-capacita-coop.svg";
import logoNegociosCoop from "assets/images/logo-negocios-coop.svg";

import usePointsStore from "store";
import { BiCheckCircle } from "react-icons/bi";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useAuth } from "context/AuthContext";
import { actionLog } from "services/actionlog.service";
import DialogBubble from "components/dialog/dialog-bubble";

export default function Finish() {
	const {
		points,
		pontosGameSomosCoop,
		pontosGameDesempenho,
		pontosGameGovernanca,
		pontosGameEsg,
		pontosGameCapacitaCoop,
		pontosGameInovaCoop,
		souCoop,
		desempenhoCoop,
		identidadeCoop,
		governancaCoop,
		esgCoop,
		inovaCoop,
		capacitaCoop,
		negociosCoop,
		isLotteryEnabled,
	} = usePointsStore();

	const { user } = useAuth();
	const [canDisplayLottery, setCanDisplayLottery] = useState(false);

	useEffect(() => {
		async function registerPoints() {
			await actionLog("finish", { score: points });
		}

		if (isLotteryEnabled && points >= 4000) {
			setCanDisplayLottery(true);
		}

		registerPoints();
	}, [isLotteryEnabled, user, points]);

	const getClass = (value: boolean) => {
		if (value) {
			return `w-28 rounded-full outline outline-2 outline-lime-500 border-2 border-white`;
		}

		return `w-28 rounded-full outline outline-2 outline-red-500 border-2 border-white opacity-40 saturate-0`;
	};

	const CoopIcon = ({ icon, value }: { icon: string; value: boolean }) => {
		return (
			<div className="relative">
				<span className="absolute -right-2 -top-2 z-10">
					{value ? (
						<AiFillCheckCircle className="text-green-500" size={32} />
					) : (
						<AiFillCloseCircle className="text-6xl text-red-600" size={32} />
					)}
				</span>
				<img src={icon} className={getClass(value)} />
			</div>
		);
	};

	return (
		<div
			className="bg-black absolute top-0 left-0 w-full h-full"
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			<h1 className="text-6xl"></h1>

			<img
				src={logoJornada}
				className="absolute top-[175px] left-[920px] w-[600px] h-[170px] p-0 object-contain overflow-hidden"
			/>

			<div className="absolute bottom-[280px] left-[580px] z-10">
				<DialogBubble model="pink-left" className="w-[890px] h-[250px]">
					<div className="text-3xl px-20 pl-[9%] font-sans">
						<p>
							<b>
								Nossa jornada acaba aqui! Continue explorando e inovando para
								fortalecer o cooperativismo.
							</b>
						</p>
						<p className="mt-4">
							<b>Muito obrigada por fazerem parte dessa aventura!</b>
						</p>
						<p className="mt-4 text-4xl">
							<b>Até a próxima!</b>
						</p>
					</div>
				</DialogBubble>
			</div>

			<Eliza className="absolute left-[300px] -bottom-[50px] w-[400px] -scale-x-[100%]" />

			<div className="absolute top-[260px] left-[650px] text-2 text-blue-dark text-2xl text-center flex flex-col items-center">
				<h1 className="font-bold text-brand-dark-purple uppercase leading-none">
					Conheça
					<br />
					nosso portfolio:
				</h1>
				<img src={qrcode} className="w-[200px] mt-2" />
			</div>

			<div className="absolute top-[370px] w-[380px] left-[500px] right-0 mx-auto grid grid-cols-3 gap-3 justify-center items-center">
				<CoopIcon icon={logoSomosCoop} value={pontosGameSomosCoop > 0} />
				<CoopIcon icon={logoSouCoop} value={souCoop} />
				<CoopIcon icon={logoDesempenho} value={desempenhoCoop} />
				{/* <CoopIcon icon={logoIdentidade} value={identidadeCoop} /> */}
				<CoopIcon icon={logoGovernanca} value={governancaCoop} />
				{/* <CoopIcon icon={logoEsg} value={esgCoop} /> */}
				<CoopIcon icon={logoInovaCoop} value={inovaCoop} />
				<CoopIcon icon={logoCapacitaCoop} value={capacitaCoop} />
				{/* <CoopIcon icon={logoNegociosCoop} value={negociosCoop} /> */}
			</div>

			<div className="absolute top-[270px] right-[80px] text-blue-dark text-center flex flex-col items-center">
				<h1 className="font-bold uppercase leading-none  text-3xl">
					Seus Pontos:
				</h1>
				<p className="font-bold uppercase leading-none text-7xl text-purple-600">
					{points}
				</p>
				{canDisplayLottery ? (
					<>
						<hr className="w-full mt-4 mb-8" />
						<h1 className="font-bold uppercase leading-none  text-3xl">
							Número da Sorte:
						</h1>
						<p className="font-bold uppercase leading-none text-7xl text-brand-purple">
							{user?.extra.lottery}
						</p>
					</>
				) : null}
			</div>
		</div>
	);
}
