import { motion } from "framer-motion";

import LogoJornada from "components/ui/LogoJornada";
import { Platform } from "./Platform";

import mapLine from "assets/images/map-line.png";
import Element from "components/ui/Element";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPlatforms } from "utils/map";

type JouneyMapProps = {
	onboarding?: boolean;
	animation?: boolean;
};
function JouneyMap({ onboarding = false, animation = true }: JouneyMapProps) {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [shipPosition, setShipPosition] = useState({ x: 0, y: 0 });
	const [currentStep, setCurrentStep] = useState(params.id || 0);

	useEffect(() => {
		//
	}, [params]);

	return (
		<div>
			<div className="absolute top-[30px] left-[10px] z-10">
				{getPlatforms().map((item, index) => (
					<Platform
						key={index}
						icon={item.icon || ""}
						state={index === 0 ? "active" : "default"}
						position={item.position || { x: 0, y: 0 }}
						delay={animation ? index : 0}
					/>
				))}
			</div>

			<motion.img
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{
					duration: animation ? 0.3 : 0,
					delay: animation ? 0.7 : 0,
				}}
				src={mapLine}
				alt="map-line"
				className="absolute w-[1254px] h-[541px] top-[363px] left-[269px]"
			/>

			<div className="absolute top-20 right-20 ">
				<LogoJornada className="w-[325px] h-[325px] shadow-glow-blue" />
			</div>

			<Element
				image="planet1"
				className="absolute top-[220px] left-[70px] w-[180px]"
			/>

			<Element
				image="planet2"
				className="absolute top-[600px] right-[90px] w-[100px]"
			/>

			<Element
				image="planet3"
				className="absolute top-[150px] right-[770px] w-[130px]"
			/>

			<Element
				image="spaceStation"
				className="absolute top-[150px] right-[350px] w-[350px] animate animate-fly"
			/>
		</div>
	);
}

export default JouneyMap;
