import { UIElementProps } from "types/prop-types";

import logo from "assets/images/logo-ocb-dark.svg";
import logoWhite from "assets/images/logo-ocb-light.svg";

import logoOcesp from "assets/images/logo-ocesp-dark.svg";
import logoOcespWhite from "assets/images/logo-ocesp-light.svg";

export default function LogoOCB({ white, className }: UIElementProps) {
	return (
		<img
			src={white ? logoWhite : logo}
			className={`pointer-none w-[256px] ${className}`}
		/>
	);
}
