import { ActionLog } from "types/funifier";
import { api } from "./api";

export const actionLog = (actionId: string, attributes?: any): Promise<any> => {
	return new Promise((resolve, reject) => {
		const _attributes = Object.assign({}, attributes);

		const payload = {
			userId: "me",
			actionId: actionId,
			time: new Date().getTime(),
			attributes: _attributes,
		};

		api
			.post("/action/log?async=false", payload)
			.then((response) => {
				if (response.data.achievements.length) {
					const event = new Event("reload:user");
					document.dispatchEvent(event);
				}

				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const actionLogBulk = (actionId: string, data: any[]) => {
	return new Promise((resolve, reject) => {
		const payload: ActionLog[] = data.map((action) => {
			return {
				userId: "me",
				actionId: actionId,
				time: action.date || new Date().getTime(),
				attributes: {
					...action,
				},
			};
		});

		api
			.post("/action/log/bulk?async=false", payload)
			.then((response) => {
				const event = new Event("reload:user");
				document.dispatchEvent(event);
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export async function getActions(actionId: string): Promise<ActionLog[]> {
	try {
		const { data } = await api.get(`action/log`, {
			params: {
				player: "me",
				action: actionId,
				orderby: "time",
				reverse: true,
			},
		});
		return data;
	} catch (e) {
		throw e;
	}
}
