import React, { useMemo } from "react";
import { motion } from "framer-motion";

import platformDefault from "assets/images/platform/default.png";
import platformWin from "assets/images/platform/win.png";
import platformLose from "assets/images/platform/lose.png";
import platformActive from "assets/images/platform/active.png";

type PlatformProps = {
	icon: string;
	state: "default" | "win" | "lose" | "active";
	position: {
		x: number;
		y: number;
	};
	delay?: number;
};

export function Platform({
	icon,
	state = "default",
	position,
	delay = 0,
}: PlatformProps) {
	const style = useMemo(() => {
		let image = "";
		switch (state) {
			case "win":
				image = platformWin;
				break;
			case "lose":
				image = platformLose;
				break;
			case "active":
				image = platformActive;
				break;
			default:
				image = platformDefault;
		}

		return {
			backgroundImage: `url(${image})`,
			top: `${position.y}px`,
			left: `${position.x}px`,
		};
	}, [state, position]);

	return (
		<motion.div
			initial={{ scale: 0 }}
			animate={{ scale: 1 }}
			transition={{ duration: 0.3, delay: delay / 10, ease: "backOut" }}
			className="w-[247px] h-[197px] bg-no-repeat bg-center bg-contain absolute"
			style={style}
		>
			<div className="w-[140px] h-[83px] overflow-hidden mx-auto bg-white border-2 border-white rounded-full">
				<img
					src={icon}
					alt="icon"
					className="object-cover object-center h-full w-full"
				/>
			</div>
		</motion.div>
	);
}
