import React from "react";
import { motion } from "framer-motion";

interface CircularRevealProps {
	x: number;
	y: number;
	size: number;
	overlayColor?: string;
	overlayOpacity?: number;
}

const CircularReveal: React.FC<CircularRevealProps> = ({
	x,
	y,
	size,
	overlayColor = "black",
	overlayOpacity = 0.8,
}) => {
	const portalNode = document.body;

	return (
		<div className="fixed inset-0 z-20">
			<svg className="absolute inset-0 w-full h-full">
				<defs>
					<mask id="hole-mask">
						<rect width="100%" height="100%" fill="white" />
						<motion.circle
							cx={x}
							cy={y}
							r={size}
							fill="black"
							initial={{ r: 0, cx: 0, cy: 0 }}
							animate={{ r: size, cx: x, cy: y }}
							transition={{ duration: 0.4, ease: "easeInOut" }}
						/>
					</mask>
				</defs>
				<rect
					width="100%"
					height="100%"
					fill={overlayColor}
					fillOpacity={overlayOpacity}
					mask="url(#hole-mask)"
				/>
			</svg>
		</div>
	);
};

export default CircularReveal;
