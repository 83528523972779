import bg from "assets/images/bg/bg-somos-coop.jpg";
import { useCallback, useEffect, useState } from "react";
import usePointsStore from "store";
import { motion } from "framer-motion";

import carimbo from "assets/images/selo.svg";
import AchievementModal from "components/modal/AchievementModal";

export default function SomosCoopGame() {
	const {
		points,
		pontosGameSomosCoop,
		setPontosGameSomosCoop,
		toggleNavigation,
	} = usePointsStore();

	const onMessage = useCallback(
		({ data }: any) => {
			if (data.type === "game-finished") {
				toggleNavigation(true);
				if (data.score === 3) {
					setPontosGameSomosCoop(300);
				}
			}
		},
		[toggleNavigation, points]
	);

	useEffect(() => {
		toggleNavigation(false);
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
			toggleNavigation(true);
		};
	}, [toggleNavigation]);

	return (
		<>
			{pontosGameSomosCoop ? (
				<AchievementModal
					positive
					points={pontosGameSomosCoop}
					logo={carimbo}
					title={`Você conseguiu identificar os produtos com o carimbo SomosCoop!`}
				/>
			) : null}
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute flex flex-col p-5 gap-5 w-full h-full top-0 left-0 z-10 bg-blue-dark bg-opacity-90">
					<div className="mt-[100px] text-2xl text-white flex flex-col items-center">
						<div className="flex gap-10 items-center">
							<div className="text-center leading-tight">
								Encontre os produtos
								<br />
								com o carimbo Somoscoop
							</div>
							<img src={carimbo} width={100} />
						</div>
					</div>
					<div className="relative flex-1 bg-black rounded-xl overflow-hidden">
						<iframe
							src="/games/v3/index.html?game=somoscoop"
							className="absolute w-full h-full"
						/>
					</div>
				</div>
			</div>
		</>
	);
}
