import Dialog from "components/dialog";
import { createContext, ReactNode, useContext, useState } from "react";

interface DialogContextData {
	showDialog: (data?: any) => void;
	loadDialog: (DialogId: string, data?: any) => void;
	closeDialog: () => void;
	openDialog?: string;
	dialogData: any;
	isMuted?: boolean;
	setIsMuted?: any;
}

interface DialogProviderProps {
	children: ReactNode;
}

let showDialogTimeout: any = null;

export const DialogContext = createContext({} as DialogContextData);

function DialogProvider({ children }: DialogProviderProps) {
	const [openDialog, setOpenDialog] = useState<string | undefined>();
	const [dialogData, setDialogData] = useState<any>();
	const [isMuted, setIsMuted] = useState<boolean>(true);

	function showDialog(content?: any) {
		if (showDialogTimeout) {
			clearTimeout(showDialogTimeout);
		}
		showDialogTimeout = setTimeout(() => {
			let data = Array.isArray(content) ? content : [{ content }];
			data = data.map((item: any) => {
				if (!item.hasOwnProperty("content")) {
					return {
						content: item,
					};
				} else {
					return item;
				}
			});
			setDialogData(data);
		}, 300);
	}

	function loadDialog(DialogId: string, data?: any) {
		setDialogData(data);
		setOpenDialog(DialogId);
	}

	function closeDialog() {
		setOpenDialog(undefined);
		setDialogData(undefined);
	}

	return (
		<DialogContext.Provider
			value={{
				showDialog,
				loadDialog,
				closeDialog,
				setIsMuted,
				openDialog,
				dialogData,
				isMuted,
			}}
		>
			{children}
			<Dialog />
		</DialogContext.Provider>
	);
}

function useDialog() {
	const context = useContext(DialogContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within a AuthProvider");
	}
	return context;
}

export { DialogProvider, useDialog };
