import Eliza from "components/ui/Eliza";
import LogoOCB from "components/ui/LogoOCB";
import VolumeControl from "components/ui/VolumeControl";
import { CSSProperties, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Element from "components/ui/Element";
import LogoJornada from "components/ui/LogoJornada";
import Transition from "components/ui/Transition";
import { useAuth } from "context/AuthContext";
import { motion } from "framer-motion";
import LoginForm from "./components/LoginForm";

function Login() {
	const location = useLocation();
	const { checkGameIsEnabled } = useAuth();
	const [showForm, setShowForm] = useState(false);

	useEffect(() => {
		checkGameIsEnabled();
	}, []);

	useEffect(() => {
		// console.log(location.state);
		const locationState: any = location.state;
		if (locationState && locationState.success) {
			setShowForm(true);
		}
	}, [location]);

	return (
		<>
			<Transition />
			<div id="login" className="relative w-full h-full">
				<LogoOCB white className="z-20 absolute top-10 left-10" />

				<div className="absolute -top-10 right-[250px]">
					<LogoJornada className="w-[300px] h-[300px] shadow-glow-blue" />
				</div>

				<Element
					image="planet1"
					className="absolute top-[130px] left-[200px] w-[280px]"
				/>

				<Element
					image="planet2"
					className="absolute top-[200px] left-[970px] w-[50px] opacity-50"
				/>

				<Element
					image="spaceStation"
					className="absolute top-[120px] right-[460px] w-[150px] animate animate-fly brightness-50"
				/>

				<motion.div
					initial={{ scale: 1, translateY: 0 }}
					animate={{ scale: 2, translateY: -500 }}
					transition={{ duration: 1.5, ease: "easeOut", delay: 0.5 }}
					onAnimationComplete={() => {
						setShowForm(true);
					}}
				>
					<Element
						image="spaceShip"
						className="z-10 absolute top-0 left-0 w-full animate"
					/>
				</motion.div>

				{showForm ? <LoginForm /> : null}
			</div>
		</>
	);
}

export default Login;
