import { create } from "zustand";

type PointsStore = {
	maxPoints: number;
	points: number;
	souCoop: boolean;
	desempenhoCoop: boolean;
	identidadeCoop: boolean;
	governancaCoop: boolean;
	esgCoop: boolean;
	inovaCoop: boolean;
	capacitaCoop: boolean;
	negociosCoop: boolean;
	pontosGameSomosCoop: number;
	pontosGameDesempenho: number;
	pontosGameGovernanca: number;
	pontosGameEsg: number;
	pontosGameInovaCoop: number;
	pontosGameCapacitaCoop: number;
	pontosGameQuiz: number;
	showPoints: boolean;
	isNavigationVisible: boolean;
	isGameEnabled: boolean;
	isLotteryEnabled: boolean;
	updatePoints: () => void;
	toggleNavigation: (visible: boolean) => void;
	displayPointsNotification: (score?: number) => void;
	hidePointsNotification: () => void;
	setSouCoop: (score: boolean) => void;
	setDesempenhoCoop: (score: boolean) => void;
	setIdentidadeCoop: (score: boolean) => void;
	setGovernancaCoop: (score: boolean) => void;
	setEsgCoop: (score: boolean) => void;
	setInovaCoop: (score: boolean) => void;
	setCapacitaCoop: (score: boolean) => void;
	setNegociosCoop: (score: boolean) => void;
	setPontosGameSomosCoop: (score: number) => void;
	setPontosGameDesempenho: (score: number) => void;
	setPontosGameGovernanca: (score: number) => void;
	setPontosGameEsg: (score: number) => void;
	setPontosGameInovaCoop: (score: number) => void;
	setPontosGameCapacitaCoop: (score: number) => void;
	setPontosGameQuiz: (score: number) => void;
	setPoints: (score: number) => void;
	addPoints: (score: number) => void;
	setLotteryEnabled: (enabled: boolean) => void;
	resetPointsStore: () => void;
};

const usePointsStore = create<PointsStore>((set, get) => ({
	maxPoints: 5000,
	points: 0,
	showPoints: false,
	souCoop: false,
	desempenhoCoop: false,
	identidadeCoop: false,
	governancaCoop: false,
	esgCoop: false,
	inovaCoop: false,
	capacitaCoop: false,
	negociosCoop: false,
	pontosGameSomosCoop: 0,
	pontosGameDesempenho: 0,
	pontosGameGovernanca: 0,
	pontosGameEsg: 0,
	pontosGameInovaCoop: 0,
	pontosGameCapacitaCoop: 0,
	pontosGameQuiz: 0,
	isNavigationVisible: true,
	isGameEnabled: true,
	isLotteryEnabled: false,
	updatePoints: () => {
		const pontosGameSomosCoop = get().pontosGameSomosCoop;
		const pontosGameDesempenho = get().pontosGameDesempenho;
		const pontosGameGovernanca = get().pontosGameGovernanca;
		const pontosGameInovaCoop = get().pontosGameInovaCoop;
		const pontosGameCapacitaCoop = get().pontosGameCapacitaCoop;
		const pontosGameEsg = get().pontosGameEsg;

		const pontosSouCoop = get().souCoop ? 1000 : 0;
		const pontosDesempenho = get().desempenhoCoop ? 1000 : 0;
		const pontosIdentidade = get().identidadeCoop ? 1000 : 0;
		const pontosGovernanca = get().governancaCoop ? 1000 : 0;
		const pontosEsg = get().esgCoop ? 1000 : 0;
		const pontosInovaCoop = get().inovaCoop ? 1000 : 0;
		const pontosCapacitaCoop = get().capacitaCoop ? 1000 : 0;
		const pontosNegociosCoop = get().negociosCoop ? 1000 : 0;

		const totalPoints = pontosGameSomosCoop + pontosGameDesempenho + pontosIdentidade + pontosGameGovernanca + pontosGameEsg + pontosGameInovaCoop + pontosGameCapacitaCoop + pontosSouCoop + pontosDesempenho + pontosGovernanca + pontosEsg + pontosInovaCoop + pontosCapacitaCoop + pontosNegociosCoop;

		set({ points: totalPoints });
	},
	toggleNavigation: (visible) => {
		set({ isNavigationVisible: visible });
	},
	displayPointsNotification: (score = 1000) => {
		set({ points: score, showPoints: true });
	},
	hidePointsNotification: () => {
		set({ showPoints: false });
	},
	setSouCoop: (score: boolean) => {
		set({ souCoop: score });
		get().updatePoints();
	},
	setDesempenhoCoop: (score: boolean) => {
		set({ desempenhoCoop: score });
		get().updatePoints();
	},
	setIdentidadeCoop: (score: boolean) => {
		set({ identidadeCoop: score });
		get().updatePoints();
	},
	setGovernancaCoop: (score: boolean) => {
		set({ governancaCoop: score });
		get().updatePoints();
	},
	setEsgCoop: (score: boolean) => {
		set({ esgCoop: score });
		get().updatePoints();
	},
	setInovaCoop: (score: boolean) => {
		set({ inovaCoop: score });
		get().updatePoints();
	},
	setCapacitaCoop: (score: boolean) => {
		set({ capacitaCoop: score });
		get().updatePoints();
	},
	setNegociosCoop: (score: boolean) => {
		set({ negociosCoop: score });
		get().updatePoints();
	},
	setPontosGameSomosCoop: (score: number) => {
		set({ pontosGameSomosCoop: score });
		get().updatePoints();
	},
	setPontosGameDesempenho: (score: number) => {
		set({ pontosGameDesempenho: score });
		get().updatePoints();
	},
	setPontosGameGovernanca: (score: number) => {
		set({ pontosGameGovernanca: score });
		get().updatePoints();
	},
	setPontosGameEsg: (score: number) => {
		set({ pontosGameEsg: score });
		get().updatePoints();
	},
	setPontosGameInovaCoop: (score: number) => {
		set({ pontosGameInovaCoop: score });
		get().updatePoints();
	},
	setPontosGameCapacitaCoop: (score: number) => {
		set({ pontosGameCapacitaCoop: score });
		get().updatePoints();
	},
	setPontosGameQuiz: (score: number) => {
		set({ pontosGameQuiz: score });
		get().updatePoints();
	},
	setPoints: (score: number) => {
		set({ points: score });
	},
	addPoints: (score: number) => {
		set({
			points: get().points + score
		});
	},
	setLotteryEnabled: (enabled: boolean) => {
		set({ isLotteryEnabled: enabled });
	},
	resetPointsStore: () => {
		set({
			points: 0,
			souCoop: false,
			desempenhoCoop: false,
			governancaCoop: false,
			esgCoop: false,
			inovaCoop: false,
			capacitaCoop: false,
			negociosCoop: false,
			pontosGameSomosCoop: 0,
			pontosGameDesempenho: 0,
			pontosGameGovernanca: 0,
			pontosGameInovaCoop: 0,
			pontosGameCapacitaCoop: 0,
			pontosGameQuiz: 0,
			showPoints: false,
		});
	},
}));

export default usePointsStore;
