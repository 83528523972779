import Back from "components/ui/Back";
import Navigation from "components/ui/Navigation";
import Score from "components/ui/Score";
import { useAuth } from "context/AuthContext";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import JouneyMap from "components/map/JouneyMap";
import Eliza from "components/ui/Eliza";
import { motion } from "framer-motion";
import usePointsStore from "store";
import { getPlatform } from "utils/map";

function Journey() {
	const { signOut } = useAuth();
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { toggleNavigation } = usePointsStore();

	const [shipInitialPosition, setShipInitialPosition] = useState(
		getPlatform("somoscoop")?.ship
	);
	const [shipFinalPosition, setShipFinalPosition] = useState(
		getPlatform("somoscoop")?.ship
	);

	const handleSignout = useCallback(() => {
		signOut();
		navigate("/");
	}, [signOut, navigate]);

	const handleOnAnimationComplete = useCallback(() => {
		if (params && params.id && location.state && shipFinalPosition?.x !== "0") {
			setTimeout(() => {
				toggleNavigation(true);
				if (location.state.direction) {
					if (location.state.direction === "next") {
						navigate(`/passo/${parseInt(params.id || "0") + 1}`);
					} else {
						navigate(`/passo/${parseInt(params.id || "0") - 1}`);
					}
				}
			}, 500);
		}
	}, [params, navigate, location, shipFinalPosition]);

	const updateShipPosition = useCallback(() => {
		const next = location.state?.direction === "next";

		let initialPosition = "";
		let finalPosition = "";

		// console.log(params.id, next);

		if (params.id === "3") {
			toggleNavigation(false);
			initialPosition = next ? "somoscoop" : "soucoop";
			finalPosition = next ? "soucoop" : "somoscoop";
		} else if (params.id === "5") {
			toggleNavigation(false);
			initialPosition = next ? "soucoop" : "anuario";
			finalPosition = next ? "anuario" : "soucoop";
		} else if (params.id === "7") {
			toggleNavigation(false);
			initialPosition = next ? "anuario" : "governanca";
			finalPosition = next ? "desempenho" : "anuario";
		} else if (params.id === "10") {
			toggleNavigation(false);
			initialPosition = next ? "desempenho" : "governanca";
			finalPosition = next ? "governanca" : "desempenho";
		} else if (params.id === "13") {
			toggleNavigation(false);
			initialPosition = next ? "desempenho" : "inovacoop";
			finalPosition = next ? "inovacoop" : "governanca";
		} else if (params.id === "16") {
			toggleNavigation(false);
			initialPosition = next ? "inovacoop" : "negocioscoop";
			finalPosition = next ? "capacitacoop" : "inovacoop";
		} else if (params.id === "19") {
			toggleNavigation(false);
			initialPosition = next ? "capacitacoop" : "negocioscoop";
			finalPosition = next ? "negocioscoop" : "capacitacoop";
		} else if (params.id === "21") {
			toggleNavigation(false);
			initialPosition = next ? "negocioscoop" : "finish";
			finalPosition = next ? "finish" : "negocioscoop";
		}

		if (initialPosition && finalPosition) {
			setShipInitialPosition(getPlatform(initialPosition)?.ship);
			setShipFinalPosition(getPlatform(finalPosition)?.ship);
		}
	}, [params, location]);

	useEffect(() => {
		updateShipPosition();
	}, [params]);

	return (
		<>
			<Score />
			<Navigation />

			<div className="z-50 absolute top-5 left-5">
				<Back
					isHome
					onClick={() => handleSignout()}
					className="hover:scale-105 cursor-pointer"
				/>
			</div>

			<div className="absolute left-0 top-0 z-0 w-full h-full">
				<motion.div
					className="absolute z-20"
					initial={{
						x: shipInitialPosition?.x || 0,
						y: shipInitialPosition?.y || 0,
						scale: 0.3,
					}}
					animate={{
						x: shipFinalPosition?.x || 0,
						y: shipFinalPosition?.y || 0,
						scale: shipFinalPosition?.scale || 0.3,
					}}
					transition={{ duration: 0.5 }}
					onAnimationComplete={handleOnAnimationComplete}
				>
					<Eliza
						name="ship"
						className="w-[900px] scale-x-[-100%] animate animate-fly"
					/>
				</motion.div>
				<JouneyMap />
			</div>

			<div className="absolute z-10 w-full h-full">
				<Outlet />
			</div>
		</>
	);
}

export default Journey;
