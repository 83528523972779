import type {
	AxiosDefaults,
	AxiosError,
	AxiosHeaders,
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios";

import {
	getToken,
	removeTokenCookies,
} from "../utils/tokenCookies";


export function setAuthorizationHeader(
	request: AxiosDefaults | AxiosHeaders | AxiosRequestConfig,
	token: string,
) {
	if (request.headers && token) {
		request.headers.Authorization = `Bearer ${token.replace("Bearer ", "")}`;
	}
}

function onRequest(
	config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
	const token = getToken()
	config.headers["Content-Type"] = "application/json";
	if (token) {
		setAuthorizationHeader(config, token);
	}
	return config;
}

function onRequestError(error: AxiosError): Promise<AxiosError> {
	return Promise.reject(error);
}

function onResponse(response: AxiosResponse): AxiosResponse {
	return response;
}

function onResponseError(
	error: AxiosError,
): Promise<AxiosError | AxiosResponse> {
	if (error.response?.status === 401 && window.location.pathname !== "/") {
		removeTokenCookies();
		window.location.href = "/";
	}
	return Promise.reject(error);
}

export function setupInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
	axiosInstance.interceptors.request.use(onRequest, onRequestError);
	axiosInstance.interceptors.response.use(onResponse, onResponseError);
	return axiosInstance;
}
