import { motion } from "framer-motion";
import usePointsStore from "store";

import icon from "../../assets/images/icon-score.svg";
import iconBonus from "../../assets/images/icon-score-bonus.svg";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "context/AuthContext";
import { fetchLotteryTickets } from "services/jornada.service";

function Score() {
	const { user } = useAuth();
	const { points, maxPoints } = usePointsStore();

	const [canWin, setCanWin] = useState(true);

	const checkLottery = useCallback(async () => {
		if (!user) return;
		const result = await fetchLotteryTickets(user?._id);

		if (result) {
			setCanWin(false);
		}
	}, [user]);

	useEffect(() => {
		checkLottery();
	}, [user]);

	return (
		<div className="border-b-4 border-b-brand-light-purple shadow shadow-xl animate-in slide-in-from-top-10 flex justify-between items-center p-6 gap-4 bg-brand-purple rounded-bl-xl rounded-br-xl z-50 absolute top-0 left-0 right-0 w-[780px] h-[90px] mx-auto">
			{canWin ? (
				<>
					<div className="flex-1 relative h-[32px] rounded-full shadow shadow-brand-light-purple bg-black">
						<div className="relative w-full h-full overflow-hidden rounded-full">
							{maxPoints ? (
								<motion.div
									initial={{ width: 0 }}
									animate={{ width: `${(points / maxPoints) * 100}%` }}
									transition={{ duration: 0.5 }}
									className="bg-lime-500 h-full shadow-md shadow-inner"
								/>
							) : null}
							<div className="mix-blend-hue bg-pink-800 absolute z-10 h-full top-0 right-[0] w-[20%]"></div>
							<div className="mix-blend-hue opacity-50 bg-pink-800 absolute z-10 h-full top-0 right-[0] w-[40%]"></div>
						</div>
						<div className="absolute z-10 h-full top-0 left-0 pt-1 px-3 rounded-full bg-black bg-opacity-20 flex items-center gap-2 text-white text-shadow-lg">
							<b className="text-xl">{points}</b> <span>pontos</span>
						</div>
						<div className="absolute z-10 -top-1 right-[40%] transform translate-x-1/2 shadow rounded-full">
							<div className="w-1 h-10 bg-white"></div>
							<b className="absolute right-[0] whitespace-nowrap text-white">
								BRINDE 1
							</b>
						</div>
						<div className="absolute z-10 -top-1 right-[20%] transform translate-x-1/2 shadow rounded-full">
							<div className="w-1 h-10 bg-white"></div>
							<b className="absolute right-[0] whitespace-nowrap text-white">
								BRINDE 2
							</b>
						</div>
						<div className="absolute z-10 -top-1 right-[0%] transform translate-x-1/2 shadow rounded-full">
							<div className="w-1 h-10 bg-none"></div>
							<b className="absolute right-[0] whitespace-nowrap text-white">
								MAX
							</b>
						</div>
					</div>
					<div>
						<img
							src={iconBonus}
							alt="Bônus"
							width={56}
							className={`${
								points >= maxPoints ? "animate animate-wobble" : ""
							}`}
						/>
					</div>
				</>
			) : (
				<>
					<div className="text-white text-center flex-1 text-3xl font-bold">
						Bom ver você aqui novamente!
					</div>
				</>
			)}
		</div>
	);
}

export default Score;
