

export function formatCNPJ(cnpj: string) {
	if (!cnpj || cnpj === "undefined") return "";

	const cnpjString = cnpj.padStart(14, "0");
	const cnpjMask = cnpjString.replace(
		/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
		"$1.$2.$3/$4-$5"
	);
	return cnpjMask;
}
