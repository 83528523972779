import { motion } from "framer-motion";
import { useState } from "react";

type TransitionProps = {
	type?: "fade" | "zoom" | "circular";
	delay?: number;
	duration?: number;
	children?: React.ReactNode;
};

function Transition({
	type = "fade",
	delay = 0,
	duration = 0.3,
	children,
}: TransitionProps) {
	const [show, setShow] = useState(true);

	const variants = {
		fade: {
			initial: { opacity: 1 },
			animate: { opacity: 0 },
		},
		zoom: {
			initial: { scale: 0 },
			animate: { scale: 1 },
		},
		circular: {
			initial: { scale: 0.1 },
			animate: { scale: 1 },
		},
	};

	if (!show) return null;

	return (
		<motion.div
			initial={variants[type].initial}
			animate={variants[type].animate}
			transition={{ duration, ease: "easeOut", delay }}
			className={`fixed inset-0 z-50 ${type === "circular" ? "top-0 left-0 right-0 bottom-0 m-auto w-[2000px] h-[2000px] rounded-full ring-[20000px] ring-blue-dark" : "bg-blue-dark"}`}
			onAnimationComplete={() => setShow(false)}
		>
			{children}
		</motion.div>
	);
}

export default Transition;
