import bg from "assets/images/bg/bg-somos-coop.jpg";
import section from "assets/images/section/somos-coop.svg";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/selo.svg";
import DialogBubble from "components/dialog/dialog-bubble";

export default function SomosCoop() {
	return (
		<div
			className="bg-black absolute top-0 left-0 w-full h-full"
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			<div className="absolute top-32 right-20">
				<img
					src={logo}
					alt="Selo"
					className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
				/>
			</div>

			<Eliza
				name="home"
				className="absolute left-[-50px] -bottom-[50px] w-[850px] -scale-x-[100%]"
			/>

			<div className="absolute w-[874px] left-[400px] top-[300px] animate-in slide-in-from-left-10">
				<DialogBubble model="left">
					<p>
						O carimbo SomosCoop
						<br />
						te ajuda a identificar os produtos e serviços
						<br />
						que são de cooperativas.
					</p>

					<p className="mt-10 rounded-full py-3 text-5xl text-white bg-brand-dark-purple">
						Se tem esse carimbo, é coop.
					</p>
				</DialogBubble>
			</div>

			<img
				src={section}
				alt="Somos Coop"
				className="w-1/2 animate-in slide-in-from-right-5 absolute right-0 bottom-0 object-contain object-center"
			/>
		</div>
	);
}
