import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Eliza from "components/ui/Eliza";
import LogoJornada from "components/ui/LogoJornada";
import LogoOCB from "components/ui/LogoOCB";
import { useAuth } from "context/AuthContext";
import { useCoop } from "context/CoopContext";

import { useDialog } from "context/DialogContext";
import { FaArrowRight } from "react-icons/fa";

import usePointsStore from "store";

import DialogBubble from "components/dialog/dialog-bubble";
import AnimatedText from "components/ui/AnimatedText";

import bgMenu from "assets/images/shape-bottom-1.png";
import Element from "components/ui/Element";
import { BiFullscreen } from "react-icons/bi";

function Home() {
	const location = useLocation();
	const navigate = useNavigate();
	const { reset } = useCoop();
	const { closeDialog } = useDialog();
	const { isAuthenticated, checkGameIsEnabled } = useAuth();
	const [showForm, setShowForm] = useState(false);
	const [initialized, setInitialized] = useState(false);
	const { resetPointsStore, setLotteryEnabled } = usePointsStore();

	function toggleFullScreen() {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	}

	useEffect(() => {
		const locationState: any = location.state;
		if (locationState && locationState.success) {
			setShowForm(true);
		}
	}, [location]);

	useEffect(() => {
		reset();
		closeDialog();
		resetPointsStore();

		if (isAuthenticated) {
			navigate("/onboarding");
		}
	}, [isAuthenticated, reset, resetPointsStore]);

	useEffect(() => {
		checkGameIsEnabled();
	}, []);

	if (!initialized) {
		return (
			<>
				<div
					onClick={() => toggleFullScreen()}
					className="z-20 flex items-center justify-center w-10 h-10 absolute top-0 right-0 bg-blue-dark"
				>
					<BiFullscreen size={24} className="text-white" />
				</div>
				<div
					id="home"
					className="cursor-pointer relative w-full h-full flex items-center justify-center"
					onClick={() => setInitialized(true)}
				>
					<div className="flex flex-col items-center">
						<LogoJornada className="w-[380px] h-[380px] shadow-glow-blue" />
						<p className="text-white text-center text-3xl mt-10 font-bold">
							TOQUE PARA INICIAR
						</p>
					</div>

					<LogoOCB white className="absolute top-10 left-10" />
				</div>
			</>
		);
	}

	return (
		<>
			<div
				id="home"
				className="cursor-pointer relative w-full h-full"
				onClick={() => navigate("/login")}
			>
				<LogoOCB white className="z-20 absolute top-10 left-10" />

				<div className="absolute top-20 right-20 ">
					<LogoJornada className="w-[380px] h-[380px] shadow-glow-blue" />
				</div>

				<Eliza
					name="stars"
					className="z-20 w-[845px] absolute top-[160px] -left-16 animate-in slide-in-from-left-10"
				/>

				<Element
					image="planet1"
					className="absolute top-[600px] right-[470px] w-[300px]"
				/>

				<Element
					image="planet2"
					className="absolute top-[400px] right-[470px] w-[100px]"
				/>

				<Element
					image="spaceStation"
					className="absolute top-[400px] right-[170px] w-[200px] animate animate-fly"
				/>

				<div className="z-30 absolute left-[450px] top-[150px]">
					<AnimatedText delay={0.5}>
						<DialogBubble className="w-[952px] h-[300px]">
							Vamos juntos conhecer
							<br />
							sua cooperativa e as soluções
							<br />
							do Sistema OCB?
						</DialogBubble>
					</AnimatedText>
				</div>

				<div
					className="z-10 absolute w-full h-[380px] bottom-0 pt-16 pr-14 flex items-center justify-end bg-bottom bg-no-repeat"
					style={{
						backgroundSize: "100% auto",
						backgroundImage: `url(${bgMenu})`,
					}}
				>
					<div className="flex gap-8 items-center text-right animate-in slide-in-from-right-10">
						<div className="text-5xl text-white font-bold">
							INICIAR
							<br />
							JORNADA
						</div>
						<div className="w-[180px] h-[180px] bg-white rounded-full flex items-center justify-center shadow-xl">
							<FaArrowRight size={96} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Home;
