import { api } from "./api";
const publicHeaders = {
	headers: {
		Authorization:
			"Basic " +
			btoa(
				`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_PUBLIC_SECRETKEY}`
			),
	},
};

export async function executeLottery(lotteryId: string): Promise<any> {
	try {
		const { data } = await api.get(`/lottery/${lotteryId}/execute`, {
			...publicHeaders,
		});

		let winner = null;

		if (data && Array.isArray(data.tickets) && data.tickets.length > 0) {
			winner = data.tickets[0];
		}
		return winner;
	} catch (error) {
		console.log(error);
	}
}

export async function resetLottery(lotteryId: string): Promise<any> {
	try {
		await api.delete(`/lottery/${lotteryId}/execute`, {
			...publicHeaders,
		});
	} catch (error) {
		console.log(error);
	}
}

export async function getWinners(
	lotteryId: string,
	random = false
): Promise<any> {
	await resetLottery(lotteryId);
	const executeWinner = await executeLottery(lotteryId);

	const { data } = await api.get(`/lottery/winner?lottery=${lotteryId}`, {
		...publicHeaders,
	});

	let winner = [];

	if (data && Array.isArray(data) && data.length > 0) {
		winner = data;
	}

	const winners = await Promise.all(
		winner.map(async (item: any) => {
			const userResponse = await api.get(
				`/player/${random ? executeWinner.player : item.player}`,
				{
					...publicHeaders,
				}
			);
			return userResponse.data;
		})
	);

	return winners;
}
