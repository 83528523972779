import { motion } from "framer-motion";
import JouneyMap from "components/map/JouneyMap";
import Back from "components/ui/Back";
import Score from "components/ui/Score";
import { useAuth } from "context/AuthContext";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import buttonLeft from "../../assets/images/nav/button-left.png";
import buttonRight from "../../assets/images/nav/button-right.png";
import CircularReveal from "components/ui/CircularMask";
import Eliza from "components/ui/Eliza";
import DialogBubble from "components/dialog/dialog-bubble";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

type OnboardingStep = {
	x: number;
	y: number;
	size: number;
};

const onboardingSteps: OnboardingStep[] = [
	{
		x: 470,
		y: 720,
		size: 180,
	},
	{
		x: 470,
		y: 720,
		size: 180,
	},
	{
		x: 470,
		y: 720,
		size: 180,
	},
	{
		x: 470,
		y: 720,
		size: 2000,
	},
];

function Onboarding() {
	const { signOut } = useAuth();
	const navigate = useNavigate();
	const [currentStep, setCurrentStep] = useState(0);
	const [currentOnboardingStep, setCurrentOnboardingStep] =
		useState<OnboardingStep>();

	const handleSignout = useCallback(() => {
		signOut();
		navigate("/");
	}, [signOut, navigate]);

	const goToStep = useCallback(
		(index: number) => {
			const nextStep = currentStep + index;
			if (nextStep > 3) return;
			if (nextStep >= 0) {
				setCurrentStep((prev) => prev + index);
			} else {
				setCurrentStep(0);
			}
		},
		[navigate, signOut, currentStep]
	);

	useEffect(() => {
		const nextOnboardingStep = onboardingSteps[currentStep];
		if (nextOnboardingStep) {
			setCurrentOnboardingStep(nextOnboardingStep);
		}
	}, [currentStep]);

	return (
		<>
			{currentOnboardingStep ? (
				<CircularReveal
					x={currentOnboardingStep.x}
					y={currentOnboardingStep.y}
					size={currentOnboardingStep.size}
				/>
			) : null}

			<Score />

			<div className="z-20 absolute top-5 left-5">
				<Back
					isHome
					onClick={() => handleSignout()}
					className="hover:scale-105 cursor-pointer"
				/>
			</div>

			<Outlet />

			<JouneyMap onboarding />

			{currentStep === 0 ? (
				<div className="z-30 absolute left-[500px] top-[120px] animate-in slide-in-from-right-4">
					<DialogBubble model="pink-up" className="w-[1067px] h-[285px]">
						Ao longo da jornada, precisaremos recarregar nossa nave com a
						energia do Coop!
					</DialogBubble>
				</div>
			) : null}

			{currentStep === 1 ? (
				<div className="z-30 absolute left-[460px] top-[750px] animate-in slide-in-from-right-4">
					<DialogBubble model="pink-down" className="w-[1100px] h-[268px] px-0">
						<span className="text-[41px]">
							Quanto mais soluções do Sistema OCB você participar, mais energia
							você acumula para impulsionar a sua JornadaCoop!
						</span>
					</DialogBubble>
				</div>
			) : null}

			{currentStep === 2 ? (
				<div className="z-30 absolute left-[680px] top-[270px] animate-in slide-in-from-right-4">
					<DialogBubble model="pink-up" className="w-[754px] h-[208px]">
						Nossa primeira parada
						<br />é o carimbo SomosCoop
					</DialogBubble>
				</div>
			) : null}

			<motion.div
				className="absolute z-20"
				initial={{ x: "250%", y: "20%", scale: 1 }}
				animate={{ x: currentStep < 2 ? "125%" : "250%", y: "20%" }}
				transition={{ duration: 0.4 }}
			>
				<Eliza name="default" className={`w-[900px]`} />
			</motion.div>

			{currentStep >= 2 ? (
				<motion.div
					className="absolute z-20"
					initial={{ x: "100%", y: "100%", scale: 1 }}
					animate={{
						x: currentStep === 2 ? ["200%", "120%"] : ["200%", "2%"],
						y: currentStep === 2 ? ["100%", "82%"] : ["100%", "57%"],
						scale: currentStep === 2 ? 1 : 0.3,
					}}
					transition={{ duration: 0.5 }}
					onAnimationComplete={() => {
						if (currentStep === 3) {
							setTimeout(() => {
								navigate("/passo/1");
							}, 500);
						}
					}}
				>
					<Eliza name="ship" className="w-[900px] scale-x-[-100%]" />
				</motion.div>
			) : null}

			<div className="absolute z-20 bottom-0 left-0 right-0 flex justify-between animate-in slide-in-from-bottom-4">
				<div
					onClick={() => goToStep(-3)}
					className="bg-contain bg-no-repeat bg-bottom w-[444px] h-[194px] cursor-pointer"
					style={{
						backgroundImage: `url(${buttonLeft})`,
					}}
				>
					<motion.div
						initial={{
							scale: 1,
						}}
						animate={{
							scale: [1, 1.1, 1],
						}}
						transition={{
							duration: 1,
						}}
						className="absolute flex items-center justify-center left-10 bottom-8 w-[88px] h-[88px] shadow bg-white rounded-full"
					>
						<FaArrowLeft size={48} />
					</motion.div>
				</div>
				<div
					onClick={() => goToStep(3)}
					className="relative bg-contain bg-no-repeat bg-bottom w-[444px] h-[194px] cursor-pointer"
					style={{
						backgroundImage: `url(${buttonRight})`,
					}}
				>
					<motion.div
						initial={{
							scale: 1,
						}}
						animate={{
							scale: [1, 1.1, 1],
						}}
						transition={{
							duration: 1,
							repeat: Infinity,
						}}
						className="absolute flex items-center justify-center right-10 bottom-8 w-[88px] h-[88px] shadow bg-white rounded-full"
					>
						<FaArrowRight size={48} />
					</motion.div>
				</div>
			</div>
		</>
	);
}

export default Onboarding;
