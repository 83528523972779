import bg from "assets/images/bg/bg-anuario.jpg";
import section from "assets/images/section/somos-coop.svg";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/logo-anuario.svg";
import { motion } from "framer-motion";

import ramoAgro from "assets/images/ramos/agro.svg";
import ramoConsumo from "assets/images/ramos/consumo.svg";
import ramoCredito from "assets/images/ramos/credito.svg";
import ramoSaude from "assets/images/ramos/saude.svg";
import ramoInfra from "assets/images/ramos/infra.svg";
import ramoTrabalho from "assets/images/ramos/tpbs.svg";
import ramoTransporte from "assets/images/ramos/transporte.svg";
import DialogBubble from "components/dialog/dialog-bubble";

export default function Anuario() {
	return (
		<div
			className="bg-black absolute top-0 left-0 w-full h-full"
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			<div className="absolute top-32 right-20">
				<img
					src={logo}
					alt="Selo"
					className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
				/>
			</div>

			<div className="absolute top-[300px] left-[190px]">
				<DialogBubble model="top-left" className="w-[400px] h-[210px]">
					<div className="text-2xl">
						<b>
							Quer saber os dados oficiais
							<br />
							do Coop no país?
						</b>
						<p className="mt-2 underline">Acesse: anuario.coop.br</p>
					</div>
				</DialogBubble>
			</div>

			<Eliza
				name="ship"
				className="absolute left-[50px] bottom-[250px] w-[600px] animate animate-fly"
			/>

			<div className="absolute bottom-[50px] right-[480px] p-10 rounded-xl bg-white bg-opacity-85 backdrop-blur border-b-8 border-b-white flex flex-col items-center gap-10 shadow-glow-blue">
				<h1 className="text-3xl font-bold text-brand-purple text-center">
					Atualmente, nosso movimento abrange mais de:
				</h1>

				<div className="flex items-center divide-x">
					<div className="text-center text-xl text-blue-dark px-10">
						<h2 className="text-4xl">
							<b className="text-6xl">4,5</b> mil
						</h2>
						<b>COOPERATIVAS</b>
					</div>

					<div className="text-center text-xl text-blue-dark px-10">
						<h2 className="text-4xl">
							<b className="text-6xl">23,4</b> milhões
						</h2>
						<b>DE COOPERADOS</b>
					</div>

					<div className="text-center text-xl text-blue-dark px-10">
						<h2 className="text-4xl">
							<b className="text-6xl">550</b> mil
						</h2>
						<b>EMPREGOS</b>
					</div>
				</div>
			</div>

			<motion.img
				src={ramoCredito}
				alt=""
				width={150}
				className="absolute top-[220px] left-[750px] rounded-full shadow-glow-blue border-2 border-white"
				initial={{ y: 0 }}
				animate={{ y: [0, 35, 0] }}
				transition={{ duration: 5, ease: "easeInOut", repeat: Infinity }}
			/>

			<motion.img
				src={ramoAgro}
				alt=""
				width={150}
				className="absolute top-[420px] left-[620px] rounded-full shadow-glow-blue border-2 border-white"
				initial={{ y: 0 }}
				animate={{ y: [0, -20, 0] }}
				transition={{ duration: 6, ease: "easeInOut", repeat: Infinity }}
			/>

			<motion.img
				src={ramoSaude}
				alt=""
				width={150}
				className="absolute top-[500px] left-[800px] rounded-full  shadow-glow-blue border-2 border-white"
				initial={{ y: 0 }}
				animate={{ y: [0, 40, 0] }}
				transition={{ duration: 4, ease: "easeInOut", repeat: Infinity }}
			/>

			<motion.img
				src={ramoConsumo}
				alt=""
				width={150}
				className="absolute top-[270px] left-[1000px] rounded-full  shadow-glow-blue border-2 border-white"
				initial={{ y: 0 }}
				animate={{ y: [0, 35, 0] }}
				transition={{ duration: 4, ease: "easeInOut", repeat: Infinity }}
			/>

			<motion.img
				src={ramoInfra}
				alt=""
				width={150}
				className="absolute top-[520px] left-[1030px] rounded-full  shadow-glow-blue border-2 border-white"
				initial={{ y: 0 }}
				animate={{ y: [0, -40, 0] }}
				transition={{ duration: 5, ease: "easeInOut", repeat: Infinity }}
			/>

			<motion.img
				src={ramoTrabalho}
				alt=""
				width={150}
				className="absolute top-[420px] left-[1220px] rounded-full shadow-glow-blue border-2 border-white"
				initial={{ y: 0 }}
				animate={{ y: [0, -30, 0] }}
				transition={{
					duration: 3,
					ease: "easeInOut",
					repeat: Infinity,
				}}
			/>

			<motion.img
				src={ramoTransporte}
				alt=""
				width={150}
				className="absolute top-[550px] left-[1400px] rounded-full shadow-glow-blue border-2 border-white"
				initial={{ y: 0 }}
				animate={{ y: [0, -40, 0] }}
				transition={{
					duration: 4.8,
					ease: "easeInOut",
					repeat: Infinity,
				}}
			/>

			{/* <img
				src={section}
				alt="Somos Coop"
				className="w-1/2 animate-in slide-in-from-right-5 absolute right-0 bottom-0 object-contain object-center"
			/> */}
		</div>
	);
}
