import { create } from "zustand";

type AuthStore = {
  loginType: string | null;
  setLoginType: (loginType: string) => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  loginType: null,
  setLoginType: (loginType) => set({ loginType }),
}));

export default useAuthStore;
