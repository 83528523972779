import bg from "assets/images/bg/bg-negocios-coop.jpg";
import section from "assets/images/section/negocios-coop.png";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/logo-negocios-coop.svg";
import usePointsStore from "store";
import DialogBubble from "components/dialog/dialog-bubble";
import { motion } from "framer-motion";

const staggerContainer = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.2,
		},
	},
};

const staggerItem = {
	hidden: { opacity: 0, y: 20 },
	show: { opacity: 1, y: 0 },
};

export default function NegociosCoop() {
	const { setNegociosCoop } = usePointsStore();

	return (
		<div
			className="bg-black absolute top-0 left-0 w-full h-full"
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			<div className="absolute top-32 right-20">
				<img
					src={logo}
					alt="Selo"
					className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
				/>
			</div>

			<div className="absolute top-[220px] left-[30px] z-10">
				<DialogBubble model="pink-top-left" className="w-[620px] h-[330px]">
					<div className="text-2xl px-6">
						<b>
							Lá na plataforma NegociocCoop as cooperativas têm acesso a
							análises de mercado, tendências e dados relevantes que auxiliam na
							tomada de decisões estratégicas
						</b>
						<p className="mt-2 underline">Acesse: negocios.coop.br</p>
					</div>
				</DialogBubble>
			</div>

			<Eliza
				name="home"
				className="z-10 absolute left-[-50px] -bottom-[40px] w-[700px] -scale-x-[100%]"
			/>

			<div className="flex flex-col gap-10 justify-center items-center absolute bg-white bg-opacity-85 backdrop-blur top-[200px] left-0 right-0 mx-auto w-[800px] h-[800px]  border-b-8 border-b-white px-16 rounded-xl shadow-glow-blue">
				<h1 className="text-3xl font-bold text-center">
					Ela promove diversas oportunidades
					<br />
					de negócio, facilitando parcerias
					<br />e acordos comerciais vantajosos,
					<br />
					no mercado nacional e internacional!
				</h1>

				<motion.div
					id="items"
					className="stagger-container flex flex-wrap justify-center gap-10"
					variants={staggerContainer}
					initial="hidden"
					animate="show"
				>
					<motion.div
						variants={staggerItem}
						className="stagger-item text-center flex flex-col items-center"
					>
						<img
							src="https://negocios.coop.br/wp-content/uploads/2022/04/Rodadas-negocios-internacional.jpg"
							alt=""
							className="w-[180px] h-[180px] object-cover object-center rounded-full border-4 border-white shadow"
						/>
						<div className="p-2 px-5 leading-none bg-brand-purple rounded-full text-white font-bold mt-4 text-xl shadow">
							RODADAS
							<br />
							DE NEGÓCIOS
						</div>
					</motion.div>
					<motion.div
						variants={staggerItem}
						className="stagger-item text-center flex flex-col items-center"
					>
						<img
							src="https://negocios.coop.br/wp-content/uploads/2022/04/missoes-Feiras-internacionais.jpg"
							alt=""
							className="w-[180px] h-[180px] object-cover object-center rounded-full border-4 border-white shadow"
						/>
						<div className="p-2 px-5 leading-none bg-brand-purple rounded-full text-white font-bold mt-4 text-xl shadow">
							MISSÕES
							<br />E FEIRAS
						</div>
					</motion.div>
					<motion.div
						variants={staggerItem}
						className="stagger-item text-center flex flex-col items-center"
					>
						<img
							src="https://negocios.coop.br/wp-content/uploads/2022/04/Compras-publicas.jpg"
							alt=""
							className="w-[180px] h-[180px] object-cover object-center rounded-full border-4 border-white"
						/>
						<div className="p-2 px-5 leading-none bg-brand-purple rounded-full text-white font-bold mt-4 text-xl shadow">
							COMPRAS
							<br />
							PÚBLICAS
						</div>
					</motion.div>
					<motion.div
						variants={staggerItem}
						className="stagger-item text-center flex flex-col items-center"
					>
						<img
							src="https://negocios.coop.br/wp-content/uploads/2022/04/Analise_estudos.jpg"
							alt=""
							className="w-[180px] h-[180px] object-cover object-center rounded-full border-4 border-white"
						/>
						<div className="p-2 px-5 leading-none bg-brand-purple rounded-full text-white font-bold mt-4 text-xl shadow">
							ANÁLISES
							<br />
							ECONÔMICAS
						</div>
					</motion.div>
					<motion.div
						variants={staggerItem}
						className="stagger-item text-center flex flex-col items-center"
					>
						<img
							src="https://negocios.coop.br/wp-content/uploads/2022/08/EstudosEPublicac%CC%A7o%CC%83es.jpg"
							alt=""
							className="w-[180px] h-[180px] object-cover object-center rounded-full border-4 border-white"
						/>
						<div className="p-2 px-5 leading-none bg-brand-purple rounded-full text-white font-bold mt-4 text-xl shadow">
							ESTUDOS
							<br />E PUBLICAÇÕES
						</div>
					</motion.div>
				</motion.div>

				{/* <h1 className="text-2xl pb-10 uppercase text-center">
					Com mais de <b>200 cursos online</b>, a plataforma oferece o
					conhecimento necessário para impulsionar o sucesso e a eficiência das
					cooperativas brasileiras.
				</h1> */}
			</div>

			<img
				src={section}
				alt="Somos Coop"
				className="w-[680px] animate-in slide-in-from-right-5 absolute -right-12 bottom-20 object-contain object-center"
			/>
		</div>
	);
}
