import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingModal } from "components/modal/LoadingModal";
import Button from "components/ui/Button";
import { SignUpCredentials, useAuth } from "context/AuthContext";
import {
	MutableRefObject,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { set, useForm } from "react-hook-form";
import { BiEdit, BiFullscreen } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Keyboard, { KeyboardHandlerEvent } from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { toast } from "react-toastify";
import { inputClass } from "styles";
import * as yup from "yup";
import "../styles.scss";
import { motion } from "framer-motion";
import spaceShipPanel from "assets/images/space-ship-panel.png";
import OtpInput from "react-otp-input";

import {
	fetchDataCoopsRegulares,
	fetchDataCredito,
	fetchDataDemais,
	fetchDataDemaisIdentidade,
	fetchDataGestao,
	fetchDataNegociosCoop,
	fetchDataSaude,
	fetchDataSouCoop,
	searchCooperado,
} from "services/jornada.service";
import { formatCNPJ } from "services/utils";
import { IFormInputs } from "types/login";
import { cnpjValidation, cpfValidation, validateEmail } from "utils/helpers";
import Back from "components/ui/Back";

const registerSchema = yup.object({
	search: yup.string(),
	cnpj: yup.string().length(18).required("COOP Inválida"),
	email: yup.string().email("E-mail inválido").required("E-mail obrigatório"),
	cpf: yup.string().required("CPF obrigatório"),
	name: yup.string().required("Você não digitou seu nome"),
});

const defaultKeyboardLayout = {
	default: [
		"q w e r t y u i o p {bksp}",
		"a s d f g h j k l {enter}",
		"{shift} z x c v b n m , . {shift}",
		"{alt} {space} {back}",
	],
	email: [
		"q w e r t y u i o p {bksp}",
		"a s d f g h j k l {enter}",
		"{shift} z x c v b n m , . {shift}",
		"{alt} {space} @ {altright}",
	],
	shift: [
		"Q W E R T Y U I O P {bksp}",
		"A S D F G H J K L {enter}",
		"{shiftactivated} Z X C V B N M , . {shiftactivated}",
		"{alt} {space} {altright}",
	],
	alt: [
		"1 2 3 4 5 6 7 8 9 0 {bksp}",
		`@ # $ & * ( ) ' " {enter}`,
		"{shift} % _ - + = / ; : ! ? {shift}",
		"{default} {space} {back}",
	],
};

const defaultCNPJOptions: any[] = [
	{ value: "08686987000151", label: "08.686.987/0001-51" },
];

export default function LoginRegister() {
	const navigate = useNavigate();

	const { user, setRegisterUser, login, signUp, refreshUser } = useAuth();
	const [loading, setLoading] = useState(false);
	const [formStep, setFormStep] = useState(0);
	const [keyboardLayout, setKeyboardLayout] = useState("default");
	const [confirmLgpd, setConfirmLgpd] = useState(false);
	const [allowNewsletter, setAllowNewsletter] = useState(true);
	const keyboardRef = useRef(null) as MutableRefObject<KeyboardHandlerEvent>;

	const inputMaskRef = useRef(null) as MutableRefObject<any>;
	const [isShow, setIsShow] = useState(false);
	const [isStepValid, setIsStepValid] = useState(false);
	const [cnpjOptions, setCnpjOptions] = useState(defaultCNPJOptions);
	const [selectedCoop, setSelectedCoop] = useState<any>();

	const [searchResults, setSearchResults] = useState<any[]>([]);

	const [fastPass, setFastPass] = useState();

	const [confirmCoop, setConfirmCoop] = useState(false);

	const [registeredUser, setRegisteredUser] = useState();

	const {
		control,
		register,
		handleSubmit,
		clearErrors,
		setError,
		setValue,
		getValues,
		formState: { errors },
		reset,
		watch,
	} = useForm<IFormInputs>({
		resolver: yupResolver(registerSchema),
		reValidateMode: "onChange",
	});

	const cnpjValue = watch("cnpj");

	function toggleFullScreen() {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	}

	function isValid(key: any, value: string) {
		return getValues(key) === value;
	}

	const onRegister = async (data: IFormInputs) => {
		clearErrors();
		setLoading(true);

		const payload = {
			...data,
			cnpj: `${parseInt(data.cnpj?.replace(/\D/g, "") || "0")}`,
			newsletter: allowNewsletter,
		};

		try {
			const result: any = await signUp(payload as SignUpCredentials);

			// await login({ username: `${result._id}`, password: `${payload.cnpj}` });

			setLoading(false);

			setRegisterUser(result);
			setFastPass(result.fastPass);

			setTimeout(() => {}, 1000);

			// setTimeout(() => {
			// 	navigate("/passo/1");
			// }, 1000);

			// navigate("/passo/1");
			// setRegisterUser(data);
		} catch (err: any) {
			// setError("name", { message: err });

			setLoading(false);

			toast(
				`Dados inválidos! Por favor revise o CNPJ, e-mail e nome informados.`,
				{
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);

			//@todo tratar NOME já existentes
		}

		// const cleanName : string = data?.email?.replace("/[^a-zA-Z0-9]/", "");
		// login({username: `${data.email}${cleanName}`, password: `${data.cnpj}`});

		// setLoading(true);

		// setTimeout(()=>{
		// 	navigate("/passo/1");
		// });
	};

	const onChange = (e: any, a: any) => {
		console.log("onChange", e);
		clearErrors();

		if (!e && a.target.innerText === "ENTER") return;

		switch (formStep) {
			case 0:
				setValue("search", e.toUpperCase());

				// console.log(inputMaskRef.current);
				// inputMaskRef.current.inputValue = cnpj;
				// document.getElementById("cnpj")?.focus();

				if (e.length > 3) {
					searchCoop(e.toUpperCase(), (result: any[]) =>
						setSearchResults(result)
					);
				}

				break;
			case 1:
				setValue("name", e.toUpperCase());
				break;
			case 2:
				setValue("email", e);
				break;
			case 3:
				setValue("cpf", e);
				break;
			default:
				break;
		}
	};

	const onKeyPress = (e: any) => {
		if (e === "{enter}") {
			nextStep();
		} else if (e === "{alt}" || e === "{altright}") {
			setKeyboardLayout("alt");
		} else if (e === "{shift}") {
			setKeyboardLayout("shift");
		} else if (e === "{default}" || e === "{shiftactivated}") {
			setKeyboardLayout("default");
		} else if (e === "{back}") {
			nextStep();
		}
	};

	function nextStep() {
		clearErrors();

		setValue("search", "");

		if (keyboardRef.current) {
			keyboardRef.current.clearInput("keyboard");
		}

		// console.log(getValues());

		if (formStep === 0) {
			if (!cnpjValidation(getValues("cnpj"))) {
				setError("cnpj", { message: "CNPJ inválido" });
				return;
			}

			setFormStep(formStep + 1);
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
			return;
		} else if (formStep === 1) {
			const name = getValues("name");
			if (!name || name.length < 3) {
				setError("name", { message: "Nome inválido" });
				return;
			}
		} else if (formStep === 2) {
			const email = getValues("email") || "";
			if (!validateEmail(email)) {
				setError("email", { message: "E-mail inválido" });
				return;
			}
		} else if (formStep === 3) {
			const cpf = getValues("cpf") || "";
			if (!cpfValidation(cpf)) {
				setError("cpf", { message: "CPF inválido" });
				return;
			}
		}

		if (formStep === 5) {
			setLoading(true);
		} else {
			setFormStep(formStep + 1);
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
		}
	}

	const backStep = useCallback(() => {
		if (fastPass) {
			reset();
			setSearchResults([]);
			setFastPass(undefined);
			setFormStep(0);
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
			return;
		}

		if (formStep === 0) {
			navigate("../");
		} else {
			setFormStep(formStep - 1);
			if (keyboardRef.current) {
				keyboardRef.current.clearInput("keyboard");
			}
		}
	}, [formStep, fastPass, reset]);

	function handleStepValid() {
		if (formStep === 0) {
			console.log(getValues("cnpj"));
			return cnpjValidation(getValues("cnpj"));
		}
		return true;
	}

	function handleSearch(value: string) {
		async function onSearch(search: string) {
			try {
				// console.log(search);
				// const result = await searchCooperado(search);
			} catch (err) {}
		}
		onSearch(value);
	}

	function showLgpd() {
		setFormStep(5);
	}

	useEffect(() => {
		if (keyboardRef.current) {
			keyboardRef.current.clearInput("keyboard");
		}

		setKeyboardLayout("email");

		if (formStep === 1) {
			setValue("name", "");
		} else if (formStep === 2) {
			setValue("email", "");
			setKeyboardLayout("email");
		}

		// if (formStep ) {
		// 	setKeyboardLayout("email");
		// } else {
		// 	setKeyboardLayout("default");
		// }
	}, [formStep]);

	const searchCoop = (
		inputValue: string,
		callback: (options: any[]) => void
	) => {
		try {
			clearErrors();
			if (inputValue.length >= 3) {
				searchCooperado(inputValue).then((result) => {
					const options = result.map((item: any) => {
						return {
							value: formatCNPJ(item.cnpj),
							label: `${formatCNPJ(item.cnpj)} - ${item.nome_fantasia}`,
							data: item,
						};
					});
					callback(options);
				});
			} else {
				callback([]);
			}
			// console.log(result);
			// const options = result.map((item: any) => {
			// 	return {
			// 		value: item.cnpj,
			// 		label: `${item.cnpj} - ${item.nome_fantasia}`,
			// 	};
			// });
			// setCnpjOptions(options);
			// setLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		try {
			// searchCoop();
		} catch (err) {
			setLoading(false);
		}
	}, [searchCoop]);

	useEffect(() => {
		setFastPass(undefined);
	}, []);

	if (loading) {
		return <LoadingModal />;
	}

	return (
		<>
			<div
				onClick={() => toggleFullScreen()}
				className="z-40 flex items-center justify-center w-10 h-10 absolute top-0 right-0"
			>
				<BiFullscreen size={24} className="text-white" />
			</div>
			<motion.div
				id="login"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				transition={{ duration: 0.5 }}
				className="z-20 absolute w-full h-full bg-cover bg-center flex flex-col justify-center p-36 pb-24"
				style={{
					backgroundImage: `url('${spaceShipPanel}')`,
				}}
			>
				<Back
					className="absolute z-20 top-32 left-44"
					onClick={() => backStep()}
				/>

				{fastPass ? (
					<div
						className={`mx-auto my-16 pointer-events-none flex flex-col items-center gap-16`}
					>
						<label htmlFor="cnpj" className="block text-4xl text-center">
							<span className="text-5xl font-bold">SEU CÓDIGO DE FILA</span>
							<br />
							Anote ou tire uma foto para não esquecer :)
						</label>
						<OtpInput
							value={fastPass}
							onChange={() => {}}
							numInputs={4}
							renderSeparator={<span>-</span>}
							renderInput={(props) => (
								<input
									{...props}
									className="rounded-xl border-4 border-blue-dark"
								/>
							)}
							inputStyle={{
								width: "110px",
								height: "160px",
								fontSize: "4rem",
								fontWeight: "bold",
								lineHeight: "100%",
								margin: "0 10px",
							}}
						/>
					</div>
				) : null}

				{!fastPass ? (
					<div
						className={`mx-auto my-16`}
						style={{
							width: formStep < 3 ? `${1200}px` : `${1200}px`,
							minHeight: formStep === 0 ? `${850}px` : `${850}px`,
						}}
					>
						<form
							onSubmit={handleSubmit(onRegister)}
							className="flex flex-col justify-center text-xl text-blue-dark"
						>
							<div className="space-y-8">
								{formStep === 0 && (
									<label htmlFor="cnpj" className="block text-4xl text-center">
										<span className="text-5xl font-bold">
											ENCONTRE SUA COOP
										</span>
										<br />
										Digite o CNPJ ou NOME da cooperativa
									</label>
								)}

								{formStep === 1 && (
									<label htmlFor="name" className="block text-4xl text-center">
										<span className="text-5xl font-bold">SOBRE VOCÊ</span>
										<br />
										Digite o seu nome
									</label>
								)}

								{formStep === 2 && (
									<label htmlFor="email" className="block text-4xl text-center">
										<span className="text-5xl font-bold">SOBRE VOCÊ</span>
										<br />
										Digite o seu email corporativo
									</label>
								)}

								{formStep === 3 && (
									<label htmlFor="email" className="block text-4xl text-center">
										<span className="text-5xl font-bold">SOBRE VOCÊ</span>
										<br />
										Digite o seu CPF
									</label>
								)}

								{formStep === 4 && (
									<p className="block text-5xl text-center">
										<span className="text-5xl font-bold">TUDO CERTO?</span>
										<br />
										Por favor, confirme seus dados
									</p>
								)}

								{formStep === 5 && (
									<p className="block text-5xl text-center">
										Termos de Uso e Privacidade
									</p>
								)}

								{confirmCoop && formStep === 0 && (
									<div className="bg-white rounded-xl p-10 border-4 border-blue-dark">
										<div className="flex flex-col text-center gap-10 items-center justify-between">
											<p className="text-4xl font-bold text-brand-purple">
												Você deseja realizar a Jornada com esta cooperativa?
											</p>
											<p className="text-5xl">{selectedCoop}</p>
											<div className="flex items-center gap-10">
												<Button
													type="button"
													className="w-[300px] py-4 text-3xl disabled:opacity-50 bg-red-500"
													onClick={() => {
														setConfirmCoop(false);
													}}
												>
													NÃO
												</Button>
												<Button
													type="button"
													className="w-[300px] py-4 text-3xl disabled:opacity-50"
													onClick={() => {
														setConfirmCoop(false);
														nextStep();
													}}
												>
													SIM
												</Button>
											</div>
										</div>
									</div>
								)}

								{!confirmCoop && formStep === 0 && (
									<div>
										<input
											id="search"
											type="text"
											{...register("search")}
											className={`${inputClass} pointer-events-none`}
											placeholder="Digite o CNPJ ou NOME de sua Coop..."
										/>
										{errors.search?.message && (
											<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
												{errors.search?.message}
											</p>
										)}

										<div className="bg-white h-[200px] mt-3 rounded-lg p-2 border-4 border-blue-dark divide-y overflow-y-auto">
											{searchResults && searchResults.length
												? searchResults.map((item) => (
														<div
															key={item.value}
															className={`p-3 text-3xl ${
																cnpjValue === item.value
																	? "bg-blue-700 text-white"
																	: ""
															}`}
															onClick={() => {
																setValue("cnpj", item.value);
																if (item.data) {
																	setSelectedCoop(item.data.nome_fantasia);
																	setConfirmCoop(true);
																}
															}}
														>
															{item.label}
														</div>
												  ))
												: null}

											{!searchResults || !searchResults.length ? (
												<div className="p-3 text-3xl">
													{getValues("search")?.length
														? "Nenhuma cooperativa encontrada"
														: "Utilize o teclado abaixo para digitar o CNPJ ou NOME da sua Coop"}
												</div>
											) : null}
										</div>
									</div>
								)}

								{formStep === 1 && (
									<div>
										<input
											id="name"
											type="text"
											{...register("name")}
											className={`${inputClass} pointer-events-none`}
											placeholder="Digite aqui seu nome"
										/>
										{errors.name?.message && (
											<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
												{errors.name?.message}
											</p>
										)}
									</div>
								)}

								{formStep === 2 && (
									<div>
										<input
											id="email"
											type="text"
											{...register("email")}
											className={`${inputClass} pointer-events-none`}
											placeholder="Digite aqui seu email corporativo"
										/>
										{errors.email?.message && (
											<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
												{errors.email?.message}
											</p>
										)}
									</div>
								)}

								{formStep === 3 && (
									<div>
										<input
											id="cpf"
											type="text"
											{...register("cpf")}
											className={`${inputClass} pointer-events-none`}
											placeholder="Digite aqui seu CPF"
										/>
										{errors.cpf?.message && (
											<p className="text-white p-2 rounded-md text-2xl mt-2 bg-red-500">
												{errors.cpf?.message}
											</p>
										)}
									</div>
								)}

								{formStep === 4 && (
									<div className="pt-5">
										<div className="grid grid-cols-2 gap-8">
											<div>
												<div className="flex flex-row items-center justify-between">
													<div>
														<p className="text-4xl font-bold">COOP:</p>
														<span className="text-5xl">{selectedCoop}</span>
													</div>
												</div>

												{/* <hr className="border-blue-dark border-t-4 mt-3 border-dashed" /> */}
											</div>

											<div>
												<div className="flex flex-row items-center justify-between">
													<div>
														<p className="text-4xl font-bold">NOME:</p>
														<span className="text-5xl">{getValues().name}</span>
													</div>
												</div>

												{/* <hr className="border-blue-dark border-t-4 mt-3 border-dashed" /> */}
											</div>

											<div>
												<div className="flex flex-row items-center justify-between">
													<div>
														<p className="text-4xl font-bold">E-MAIL:</p>
														<span className="text-5xl">
															{getValues().email}
														</span>
													</div>
												</div>

												{/* <hr className="border-blue-dark border-t-4 border-dashed" /> */}
											</div>

											<div>
												<div className="flex flex-row items-center justify-between">
													<div>
														<p className="text-4xl font-bold">CPF:</p>
														<span className="text-5xl">{getValues().cpf}</span>
													</div>
												</div>
											</div>
										</div>

										<div className="mt-10 text-2xl">
											<input
												id="agreement-lgdp"
												name="agreement-lgdp"
												type="checkbox"
												className="mr-3 w-8 h-8 border border-blue-800"
												onChange={(e) => setConfirmLgpd(e.target.checked)}
											/>{" "}
											<label htmlFor="agreement-lgdp">
												Li e estou de acordo com os tratamentos dos dados
												pessoais conforme indicado no{" "}
												<span
													onClick={() => showLgpd()}
													className="text-blue-900 underline"
												>
													Aviso de Privacidade
												</span>{" "}
												da “Jornada Coop”;
											</label>
										</div>

										<div className="mt-6 text-2xl">
											<input
												id="agreement-newsletter"
												name="agreement-newsletter"
												type="checkbox"
												className="mr-3 w-8 h-8 border border-blue-800"
												defaultChecked={true}
												onClick={(e) => {
													const checked: HTMLInputElement = e.currentTarget;
													setAllowNewsletter(checked.checked);
												}}
											/>{" "}
											<label htmlFor="agreement-newsletter">
												Desejo receber comunicações sobre o Sistema OCB por
												e-mail.
											</label>
										</div>
									</div>
								)}

								{formStep === 5 && (
									<div className="flex h-[730px] flex-col">
										<div className="font-normal bg-white rounded-xl border-2 border-blue-dark space-y-5 p-5 flex-1 overflow-y-scroll">
											<p>
												<strong>AVISO DE PRIVACIDADE</strong>
											</p>
											<p>
												<strong>Identificação</strong>
											</p>
											<p>
												O&nbsp;<strong>SISTEMA OCB&nbsp;</strong>composto
												pela&nbsp;
												<strong>
													ORGANIZAÇÃO DAS COOPERATIVAS BRASILEIRAS – OCB
													NACIONAL,
												</strong>{" "}
												inscrita no CNPJ sob nº&nbsp;63.057.822/0001-29,
												<strong>
													{" "}
													SERVIÇO NACIONAL DE APRENDIZAGEM DO COOPERATIVISMO –
													SESCOOP,
												</strong>{" "}
												inscrito no CNPJ sob nº&nbsp;03.087.543/0001-86&nbsp;e
												pela
												<strong>
													{" "}
													CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS - CNCOOP,&nbsp;
												</strong>
												inscrita no CNPJ sob nº&nbsp;07.572.853/0001-47,
												estabelecido&nbsp;no Setor de Autarquias Sul, Quadra 04,
												Bloco I, Ed. Casa do Cooperativismo, Brasília/DF, é o
												responsável por definir os motivos pelos quais os seus
												dados pessoais serão utilizados.
											</p>
											<p>
												<strong>
													Dados utilizados e para quais finalidades
												</strong>
											</p>
											<p>
												Não coletaremos dados pessoais desnecessários e
												utilizaremos os dados pessoais conforme especificado no
												resumo abaixo:
											</p>
											<p>Dados Pessoais Utilizados: Nome e e-mail;</p>
											<p>
												Finalidades: 1) Possibilitar o acesso na plataforma “
												<strong>Jornada Coop</strong>”
												<i>
													; 2) Identificar se a cooperativa do usuário utiliza
													os produtos/serviços do Sistema OCB; 3) Emitir cupom
													para sorteio de brinde; 4) Enviar o cupom emitido ao
													usuário; 5) Contatar os usuários acerca da “Jornada
													Coop” e/ou sorteio; 6) Enviar comunicações oficiais
													relacionadas com o Sistema OCB.
												</i>
											</p>
											<p>
												<strong>
													Forma e duração da utilização dos seus dados
												</strong>
											</p>
											<p>
												Os dados pessoais indicados neste Aviso serão coletados
												através do formulário digital disponibilizado no
												site&nbsp;
												<a href="http://www.jornada.coop.br">
													www.jornada.coop.br
												</a>
												. Eles serão utilizados para gerar e enviar cupom para
												que o usuário participe de sorteios promovidos por nós.
												Além disso, caso você opte por receber nossas
												comunicações oficiais, enviaremos e-mails divulgando
												nossas iniciativas em prol do cooperativismo.
											</p>
											<p>
												Manteremos os dados pessoais durante o período
												necessário para o atendimento das finalidades indicadas
												neste Aviso, sendo que após, descartaremos os dados
												pessoais coletados, exceto aqueles que a conservação é
												necessária para atender obrigações legais e/ou para
												possibilitar o exercício de direitos em processo
												judicial, administrativo e/ou arbitral. Caso você
												indique que deseja receber nossas comunicações,
												manteremos seus dados enquanto você não efetuar o seu
												descadastramento de nosso mailing.
											</p>
											<p>
												<strong>
													Sobre o compartilhamento dos dados pessoais
												</strong>
											</p>
											<p>
												Os dados pessoais coletados serão compartilhados apenas
												com as pessoas essenciais ao desenvolvimento da{" "}
												<i>atividade</i>, ou seja <strong>(i)</strong> com a
												empresa responsável pelo desenvolvimento da gamificação
												e armazenamento dos dados coletados;{" "}
												<strong>(ii)</strong> com o provedor de serviço de
												correio eletrônico (e-mail) e e-mail marketing para
												envio de comunicações oficiais; e <strong>(iii)</strong>{" "}
												com os colaboradores dos nossos setores envolvidos no
												desenvolvimento da iniciativa.
											</p>
											<p>
												Os nossos colaboradores e parceiros estão
												contratualmente obrigados a adotar padrões de segurança
												no uso dos dados pessoais de acordo com a Lei Geral de
												Proteção de Dados Pessoais (LGPD).&nbsp;
											</p>
											<p>
												<strong>Transferência internacional de dados</strong>
											</p>
											<p>
												Informamos que os dados pessoais inseridos no formulário
												serão enviados e armazenados no datacenter da
												subcontratada Digital Ocean que está localizado em Nova
												York nos Estados Unidos da América. Desta forma, ao
												aceitar os termos deste aviso, você consente com a
												transferência internacional de suas informações. No
												entanto, você pode a qualquer tempo revogar este
												consentimento, hipótese em que removeremos os seus dados
												do datacenter. Porém, isso impossibilitará a sua
												participação na “<strong>Jornada Coop</strong>”.
											</p>
											<p>
												<strong>Direitos dos titulares</strong>
											</p>
											<p>
												A Lei Geral de Proteção de Dados Pessoais (LGPD) garante
												uma série de direitos sobre os dados pessoais. Eles
												podem ser exercidos encaminhando uma mensagem para&nbsp;
												<a href="mailto:privacidade@ocb.coop.br">
													<strong>privacidade@ocb.coop.br</strong>
												</a>
												<strong>.</strong>
											</p>
											<p>
												De acordo com a lei, é possível: a) confirmar a
												existência de uso dos dados pessoais; b) acessar os
												dados pessoais utilizados, caso eles estejam sendo
												usados; c) corrigir dados pessoais incompletos,
												incorretos ou desatualizados; d) anonimizar (impedir a
												identificação através do dado pessoal), bloquear ou
												eliminar os dados desnecessários, excessivos ou
												utilizados em desacordo com a lei; e) obter informações
												sobre o compartilhamento dos dados com terceiros; f) se
												opor aos tratamentos de dados pessoais realizados; g)
												revogar o consentimento previamente concedido para
												tratamento de seus dados; e/ou h) nos casos em que não
												atendermos adequadamente suas solicitações, registrar
												reclamação perante a Autoridade Nacional de Proteção de
												Dados – ANPD.&nbsp;
											</p>
											<p>
												Se recebermos uma solicitação sua para exercer qualquer
												um dos direitos acima,&nbsp;podemos pedir que você
												confirme sua identidade antes&nbsp;de atendermos a
												solicitação, com o objetivo&nbsp;de garantir que seus
												dados se mantenham&nbsp;protegidos em segurança.
											</p>
										</div>
									</div>
								)}

								{!confirmCoop && formStep > 0 ? (
									<div className="mt-2 mx-auto w-2/3 pt-3">
										{formStep < 4 && (
											<Button
												type="button"
												className="w-full py-4 text-3xl disabled:opacity-50"
												onClick={() => nextStep()}
											>
												Prosseguir
											</Button>
										)}
										{formStep === 4 && (
											<Button
												type="submit"
												disabled={!confirmLgpd}
												className="w-full disabled:opacity-50 py-6 text-3xl bg-orange-600"
											>
												Estou de acordo com os termos
											</Button>
										)}
									</div>
								) : null}

								{!confirmCoop && formStep < 4 && (
									<div className="p-5 bg-blue-dark rounded-tl-xl rounded-tr-xl absolute w-[1200px] mx-auto left-0 right-0 bottom-0">
										<Keyboard
											disableButtonHold={true}
											layout={defaultKeyboardLayout}
											layoutName={keyboardLayout}
											theme="hg-theme-default hg-theme-ios"
											buttonTheme={[
												{
													class: "hg-space",
													buttons: "{space}",
												},
												{
													class: "hg-alt",
													buttons: "{alt} {altright}",
												},
											]}
											display={{
												"{alt}": ".?123",
												"{smileys}": "\uD83D\uDE03",
												"{shift}": "⇧",
												"{shiftactivated}": "⇧",
												"{enter}": "ENTER",
												"{bksp}": "⌫",
												"{altright}": ".?123",
												"{downkeyboard}": "🞃",
												"{space}": " ",
												"{default}": "ABC",
												"{back}": "⇦",
											}}
											onChange={onChange}
											onKeyPress={onKeyPress}
											inputName="keyboard"
											keyboardRef={(r) => (keyboardRef.current = r)}
										/>
									</div>
								)}
							</div>
						</form>
					</div>
				) : null}
			</motion.div>
		</>
	);
}
