import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { RouteList } from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import { CoopProvider } from "context/CoopContext";
import { ModalProvider } from "context/ModalContext";
import ToastProvider from "context/ToastContext";
import { ViewportProvider } from "context/ViewportContext";

import { DialogProvider } from "context/DialogContext";

import { isMobile } from "react-device-detect";

const App = () => (
	<ViewportProvider>
		<BrowserRouter>
			<AuthProvider>
				<CoopProvider>
					<ModalProvider>
						<DialogProvider>
							<RouteList />
							<ToastProvider />
						</DialogProvider>
					</ModalProvider>
				</CoopProvider>
			</AuthProvider>
		</BrowserRouter>
	</ViewportProvider>
);

export default App;
