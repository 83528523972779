/**
 * Composing <Route> in React Router v6
 * https://gist.github.com/mjackson/d54b40a094277b7afdd6b81f51a0393f
 *
 * Upgrading from v5
 * https://reactrouter.com/docs/en/v6/upgrading/v5
 */
import { Route, Routes } from "react-router-dom";

import Home from "pages/Home";
import Journey from "pages/Journey";
import Locked from "pages/Locked";
import Login from "pages/Login";
import Lottery from "pages/Lottery";
import NotFound from "pages/NotFound";
import Step from "pages/Step";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import Onboarding from "pages/Onboarding";
import LoginFastRegister from "pages/Login/components/LoginFastRegister";

export const RouteList = () => (
	<Routes>
		<Route
			path="/"
			element={
				<PublicRoute>
					<Home />
				</PublicRoute>
			}
		/>

		<Route path="/locked" element={<Locked />} />

		<Route path="/sorteio" element={<Lottery />} />

		<Route path="/registro" element={<LoginFastRegister />} />

		<Route
			path="/login"
			element={
				<PublicRoute>
					<Login />
				</PublicRoute>
			}
		/>

		<Route
			path="/onboarding"
			element={
				<PrivateRoute>
					<Onboarding />
				</PrivateRoute>
			}
		/>

		<Route
			path="/passo"
			element={
				<PrivateRoute>
					<Journey />
				</PrivateRoute>
			}
		>
			<Route
				path=":id"
				element={
					<PrivateRoute>
						<Step />
					</PrivateRoute>
				}
			/>
		</Route>

		<Route path="*" element={<NotFound />} />
	</Routes>
);
