import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import spaceShipPanel from "assets/images/space-ship-panel.png";
import AnimatedText from "components/ui/AnimatedText";
import Transition from "components/ui/Transition";
import { BiRocket, BiUser } from "react-icons/bi";
import useAuthStore from "store/auth";
import LoginFastPass from "./LoginFastPass";
import LoginSelect from "./LoginSelect";
import LoginSuccess from "./LoginSuccess";
import LoginRegister from "./LoginRegister";

export function LoginForm() {
	const [step, setStep] = useState<any>(null);
	const { loginType, setLoginType } = useAuthStore();

	const handleOnAuthSuccess = () => {
		setStep("auth-success");
	};

	useEffect(() => {
		setLoginType("");
	}, [setLoginType]);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.5 }}
			className="z-20 absolute w-full h-full bg-cover bg-center flex flex-col justify-center p-36 pb-24"
			style={{
				backgroundImage: `url('${spaceShipPanel}')`,
			}}
		>
			{!step ? (
				<>
					{!loginType ? <LoginSelect /> : null}
					{loginType === "fast-pass" ? (
						<LoginFastPass onAuthSuccess={handleOnAuthSuccess} />
					) : null}
					{loginType === "register" ? (
						<LoginRegister onAuthSuccess={handleOnAuthSuccess} />
					) : null}
				</>
			) : null}
			{step === "auth-success" ? <LoginSuccess /> : null}
		</motion.div>
	);
}

export default LoginForm;
