import { createContext, ReactNode, useState } from "react";

interface ModalContextData {
	useModal: (modalId:string,data?:any) => void;
	closeModal: () => void;
	openModal?: string;
	modalData: any;
}

interface ModalProviderProps {
	children: ReactNode;
}

export const ModalContext = createContext({} as ModalContextData);

export function ModalProvider({ children }: ModalProviderProps) {

	const [openModal,setOpenModal] = useState<string|undefined>();
	const [modalData,setModalData] = useState<any>();

	function useModal(modalId:string,data?:any){
		setModalData(data);
		setOpenModal(modalId);
	}

	function closeModal(){
		setOpenModal(undefined);
		setModalData(undefined);
	}

	return (
		<ModalContext.Provider
			value={{
				useModal,
				closeModal,
				openModal,
				modalData
			}}
		>
			{children}
		</ModalContext.Provider>
	);
}
