import bg from "assets/images/bg/bg-governanca.jpg";
import section from "assets/images/section/governanca.png";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/logo-avalia-governanca.svg";
import usePointsStore from "store";
import { useEffect, useState } from "react";
import DialogBubble from "components/dialog/dialog-bubble";
import AchievementModal from "components/modal/AchievementModal";
import { useCoop } from "context/CoopContext";
import { AiOutlineCloseCircle } from "react-icons/ai";

const governancaNacional = 66.69;
const gestaoNacional = 68.81;

export default function Governanca() {
	const { setGovernancaCoop } = usePointsStore();
	const { governancaGestao } = useCoop();

	const [gestao, setGestao] = useState(0);
	const [governanca, setGovernanca] = useState(0);

	useEffect(() => {
		console.log(governancaGestao);
		if (governancaGestao) {
			setGestao(governancaGestao.gestao);
			setGovernanca(governancaGestao.governanca);
			setGovernancaCoop(true);
		}
	}, [governancaGestao, setGovernancaCoop]);

	return (
		<>
			<AchievementModal
				positive={governancaGestao}
				points={governancaGestao ? 1000 : 0}
				logo={logo}
				title={
					governancaGestao
						? `A sua coop participa do Avalia Coop - Governança e Gestão!`
						: `A sua coop não participa do Avalia Coop - Governança e Gestão!`
				}
			/>
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute top-32 right-20">
					<img
						src={logo}
						alt="Selo"
						className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
					/>
				</div>

				<div className="absolute top-[205px] left-[180px] z-10">
					<DialogBubble model="blue-top-left" className="w-[450px] h-[220px]">
						<div className="text-2xl">
							<b>
								A participação da coop no Governança e Gestão possibilita a
								melhoria contínua dos processos organizacionais.
							</b>
						</div>
					</DialogBubble>
				</div>

				<Eliza
					name="default"
					className="z-10 absolute left-[120px] -bottom-[200px] w-[500px] -scale-x-[100%]"
				/>

				{governanca ? (
					<div className="flex flex-col justify-center items-center absolute bg-white bg-opacity-85 backdrop-blur top-[200px] left-0 right-0 mx-auto w-[800px] h-[800px]  border-b-8 border-b-white px-10 rounded-xl shadow-glow-blue">
						<h1 className="text-3xl pb-10 uppercase font-bold">
							Confira os índices da sua coop
						</h1>

						<div className="mx-auto gap-20 flex items-center text-center">
							<div className="governanca flex flex-col items-center">
								<div className="text-white">
									<h1 className="text-4xl font-bold text-brand-purple">
										GOVERNANÇA
									</h1>
									<p className="text-7xl text-blue-dark font-bold">
										{governanca}%
									</p>
								</div>
								<div className="mt-10">
									<div className="relative rounded-xl w-40 h-96 bg-purple-800 flex flex-col justify-end overflow-hidden">
										<div
											className={`absolute z-20 progress w-full border-t-4 border-t-black border-dashed font-bold text-xl pt-3 leading-none`}
											style={{ height: `${governancaNacional}%` }}
										>
											<div className="bg-white bg-opacity-80 mx-2 py-2 rounded-md text-gray-700 shadow-lg">
												{governancaNacional}% NACIONAL
											</div>
										</div>
										<div
											className={`absolute progress w-full bg-yellow-500 border-t-4 border-t-white`}
											style={{ height: `${governanca}%` }}
										></div>
									</div>
								</div>
							</div>

							<div className="gestao flex flex-col items-center">
								<div className="text-white">
									<h1 className="text-4xl font-bold text-brand-purple">
										GESTÃO
									</h1>
									<p className="text-7xl text-blue-dark font-bold">{gestao}%</p>
								</div>
								<div className="mt-10">
									<div className="relative rounded-xl w-40 h-96 bg-purple-800 flex flex-col justify-end overflow-hidden">
										<div
											className={`absolute z-20 progress w-full border-t-4 border-t-black border-dashed font-bold text-xl pt-3 leading-none`}
											style={{ height: `${gestaoNacional}%` }}
										>
											<div className="bg-white bg-opacity-80 mx-2 py-2 rounded-md text-gray-700 shadow-lg">
												{gestaoNacional}% NACIONAL
											</div>
										</div>
										<div
											className={`absolute progress w-full bg-yellow-500 border-t-4 border-t-white`}
											style={{ height: `${gestao}%` }}
										></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}

				{!governanca ? (
					<div className="flex flex-col gap-16 justify-center items-center absolute bg-white bg-opacity-85 backdrop-blur top-[100px] bottom-0 left-0 right-0 m-auto w-[800px] h-[700px] border-b-8 border-b-white py-16 px-10 rounded-xl shadow-glow-blue">
						<AiOutlineCloseCircle className="text-9xl text-red-500" />
						<h1 className="text-5xl font-bold text-center">
							A sua coop não participa
							<br />
							do Governança e Gestão!
						</h1>

						<div className="font-bold text-center text-black animate animate-wiggle">
							<p className="text-3xl text-brand-dark-purple">
								Procure a Organização Estadual
								<br />
								do Sistema OCB do seu estado e saiba mais!
							</p>
						</div>
					</div>
				) : null}

				<img
					src={section}
					alt="Somos Coop"
					className="w-[750px] animate-in slide-in-from-right-5 absolute -right-10 bottom-0 object-contain object-center"
				/>
			</div>
		</>
	);
}
