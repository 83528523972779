
import logoSomosCoop from "assets/images/selo.svg";
import logoSouCoop from "assets/images/logo-sou-coop.svg";
import logoAnuario from "assets/images/logo-anuario.svg";
import logoDesempenho from "assets/images/logo-avalia-desempenho.svg";
import logoGovernanca from "assets/images/logo-avalia-governanca.svg";
import logoInova from "assets/images/logo-inova-coop.svg";
import logoCapacita from "assets/images/logo-capacita-coop.svg";
import logoNegocios from "assets/images/logo-negocios-coop.svg";

const platforms = [
  {
    id: "somoscoop",
    icon: logoSomosCoop,
    position: { x: 335, y: 600 },
    ship: {
      x: "2%",
      y: "57%",
    }
  },
  {
    id: "soucoop",
    icon: logoSouCoop,
    position: { x: 160, y: 425 },
    ship: {
      x: "-17%",
      y: "22%",
    },
  },
  {
    id: "anuario",
    icon: logoAnuario,
    position: { x: 375, y: 300 },
    ship: {
      x: "7%",
      y: "-5%",
    },
  },
  {
    id: "desempenho",
    icon: logoDesempenho,
    position: { x: 660, y: 250 },
    ship: {
      x: "38%",
      y: "-12%",
    },
  },
  {
    id: "governanca",
    icon: logoGovernanca,
    position: { x: 920, y: 405 },
    ship: {
      x: "68%",
      y: "13%",
    },
  },
  // {
  // 	icon: logoGovernanca,
  // 	position: { x: 640, y: 610 },
  // },
  // {
  // 	icon: logoEsg,
  // 	position: { x: 620, y: 655 },
  // },
  {
    id: "inovacoop",
    icon: logoInova,
    position: { x: 950, y: 772 },
    ship: {
      x: "71%",
      y: "88%",
    },
  },
  {
    id: "capacitacoop",
    icon: logoCapacita,
    position: { x: 1165, y: 572 },
    ship: {
      x: "94%",
      y: "49%",
    },
  },
  {
    id: "negocioscoop",
    icon: logoNegocios,
    position: { x: 1385, y: 442 },
    ship: {
      x: "118%",
      y: "25%",
      scale: 0.3,
    },
  },
  {
    id: "finish",
    ship: {
      x: "108%",
      y: "15%",
      scale: 0.1,
    },
  }
];

const getPlatform = (id: string) => {
  return platforms.find((item) => item.id === id);
}

const getPlatforms = () => {
  return platforms.filter((item) => item.icon);
}

export { getPlatform, getPlatforms };