type ButtonProps = {
	children: React.ReactNode;
	onClick?: () => void;
	href?: string;
	className?: string;
	disabled?: boolean;
	type?: "button" | "submit" | "reset";
	tag?: "button" | "a" | "input";
	style?: React.CSSProperties;
	color?:
		| "primary"
		| "secondary"
		| "success"
		| "danger"
		| "warning"
		| "info"
		| "light"
		| "dark";
	[key: string]: any;
};

const buttonColor: any = {
	primary: "bg-blue-800 text-white p-2 leading-none",
	light: "bg-white text-blue-800 p-2 leading-none",
	danger: "bg-orange-800 text-white p-2 leading-none",
};

export default function Button(props: ButtonProps) {
	const { children, onClick, className, disabled, type, style, color } = props;

	return (
		<button
			{...props}
			className={`${className} ${
				buttonColor[color || "primary"]
			} hover:ring hover:ring-white rounded-full font-bold`}
		>
			<div className="p-1">{children}</div>
		</button>
	);
}
