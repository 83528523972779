import bg from "assets/images/bg/bg-inova-coop.jpg";
import section from "assets/images/section/inova-coop.svg";
import Eliza from "components/ui/Eliza";
import logo from "assets/images/logo-inova-coop.svg";
import usePointsStore from "store";
import { useEffect } from "react";
import DialogBubble from "components/dialog/dialog-bubble";
import { motion } from "framer-motion";

import cursosOnline from "assets/images/icons/cursos-online.svg";
import conteudos from "assets/images/icons/conteudos-inovar.svg";
import fiquePorDentro from "assets/images/icons/fique-por-dentro.svg";
import casesInovacao from "assets/images/icons/cases-inovacao.svg";
import radarFinanciamento from "assets/images/icons/radar-financiamento.svg";
import AchievementModal from "components/modal/AchievementModal";
import { useCoop } from "context/CoopContext";

const staggerContainer = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			staggerChildren: 0.2,
		},
	},
};

const staggerItem = {
	hidden: { opacity: 0, scale: 0 },
	show: { opacity: 1, scale: 1 },
};

export default function InovaCoop() {
	const { inovaCoop } = useCoop();
	const { setInovaCoop } = usePointsStore();

	useEffect(() => {
		if (inovaCoop) {
			setInovaCoop(true);
		}
	}, [inovaCoop, setInovaCoop]);

	return (
		<>
			{inovaCoop ? (
				<AchievementModal
					positive
					points={1000}
					logo={logo}
					title={`A sua coop tem um case registrado no InovaCoop!`}
				/>
			) : null}
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute top-32 right-20">
					<img
						src={logo}
						alt="Selo"
						className="w-[400px] mx-auto mt-8 border-8 border-white rounded-full shadow-glow-blue"
					/>
				</div>

				<div className="absolute top-[125px] left-[150px] z-10">
					<DialogBubble model="blue-top-left" className="w-[460px] h-[290px]">
						<div className="text-2xl">
							{inovaCoop ? (
								<b>
									<span className="text-4xl">Parabéns!</span>
									<br />
									Sua coop tem um case publicado
									<br />
									no InovaCoop e é inspiração para outras cooperativas!
									<br />
									<p className="mt-3 underline">Acesse: inova.coop.br</p>
								</b>
							) : (
								<b>
									Tenho certeza que sua cooperativa tem pelo menos um case de
									Inovação! Que tal publicar ele no InovaCoop
									<br />e inspirar outras coops!
									<br />
									<p className="mt-5 underline">Acesse: inova.coop.br</p>
								</b>
							)}
						</div>
					</DialogBubble>
				</div>

				<Eliza
					name="center"
					className="z-10 absolute left-[50px] bottom-[-50px] w-[700px] rotate-[10deg]"
				/>

				<div className="px-16 flex flex-col gap-10 justify-center items-center absolute bg-white bg-opacity-85 backdrop-blur top-[200px] left-0 right-0 mx-auto w-[800px] h-[800px] border-b-8 border-b-white rounded-xl shadow-glow-blue">
					<h1 className="text-2xl uppercase text-center font-bold">
						{inovaCoop
							? inovaCoop.case
							: `Lá no InovaCoop você tem sempre conteúdo atualizado para apoiar a gestão da inovação na sua Coop!`}
					</h1>

					{inovaCoop ? (
						<div className="relative flex-1 bg-black bg-opacity-10 w-full max-h-[600px] rounded-lg overflow-hidden">
							<img
								src={`/cases/${inovaCoop._id}.jpeg`}
								className="h-full w-full absolute object-contain rounded-lg"
							/>
						</div>
					) : null}

					{!inovaCoop ? (
						<motion.div
							id="items"
							className="stagger-container flex flex-wrap justify-center gap-12"
							variants={staggerContainer}
							initial="hidden"
							animate="show"
						>
							<motion.div
								variants={staggerItem}
								className="stagger-item text-center flex flex-col items-center"
							>
								<img
									src={cursosOnline}
									alt=""
									className="w-[150px] h-[150px] object-contain object-center p-4"
								/>
								<div className="p-2 px-5 leading-none bg-yellow-500 rounded-full text-white font-bold mt-2 text-xl shadow">
									CURSOS
									<br />
									ONLINE
								</div>
							</motion.div>
							<motion.div
								variants={staggerItem}
								className="stagger-item text-center flex flex-col items-center"
							>
								<img
									src={conteudos}
									alt=""
									className="w-[150px] h-[150px] object-contain object-center p-4"
								/>
								<div className="p-2 px-5 leading-none bg-pink-500 rounded-full text-white font-bold mt-2 text-xl shadow">
									CONTEÚDOS
									<br />
									PARA INOVAR
								</div>
							</motion.div>
							<motion.div
								variants={staggerItem}
								className="stagger-item text-center flex flex-col items-center"
							>
								<img
									src={fiquePorDentro}
									alt=""
									className="w-[150px] h-[150px] object-contain object-center p-4"
								/>
								<div className="p-2 px-5 leading-none bg-brand-purple rounded-full text-white font-bold mt-2 text-xl shadow">
									FIQUE POR
									<br />
									DENTRO
								</div>
							</motion.div>
							<motion.div
								variants={staggerItem}
								className="stagger-item text-center flex flex-col items-center"
							>
								<img
									src={casesInovacao}
									alt=""
									className="w-[150px] h-[150px] object-contain object-center p-4"
								/>
								<div className="p-2 px-5 leading-none bg-orange-600 rounded-full text-white font-bold mt-2 text-xl shadow">
									CASES DE
									<br />
									INOVAÇÃO
								</div>
							</motion.div>
							<motion.div
								variants={staggerItem}
								className="stagger-item text-center flex flex-col items-center"
							>
								<img
									src={radarFinanciamento}
									alt=""
									className="w-[150px] h-[150px] object-contain object-center p-4"
								/>
								<div className="p-2 px-5 leading-none bg-indigo-400 rounded-full text-white font-bold mt-2 text-xl shadow">
									RADAR DE
									<br />
									FINANCIAMENTO
								</div>
							</motion.div>
						</motion.div>
					) : null}
				</div>

				<img
					src={section}
					alt="Somos Coop"
					className="w-[550px] animate-in slide-in-from-right-5 absolute right-10 bottom-2 object-contain object-center"
				/>
			</div>
		</>
	);
}
