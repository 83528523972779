import planet1 from "../../assets/images/planets/planet-1.png";
import planet2 from "../../assets/images/planets/planet-2.png";
import planet3 from "../../assets/images/planets/planet-3.png";
import planet4 from "../../assets/images/planets/planet-4.png";
import spaceShip from "../../assets/images/space-ship.png";
import spaceStation from "../../assets/images/space-station.png";

const Sources: Record<string, string> = {
	spaceStation,
	spaceShip,
	planet1,
	planet2,
	planet3,
	planet4,
};

type ElementProps = {
	image: string;
	className?: string;
};

function Element({ image, className }: ElementProps) {
	if (!image || !Sources[image]) return null;

	return (
		<div className={className}>
			<img
				src={Sources[image] || ""}
				alt={image}
				className={
					"pointer-none absolute w-full h-auto object-contain object-center"
				}
			/>
		</div>
	);
}

export default Element;
