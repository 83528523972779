import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LogoJornada from "components/ui/LogoJornada";
import LogoOCB from "components/ui/LogoOCB";
import { useAuth } from "context/AuthContext";
import { useCoop } from "context/CoopContext";

import { useDialog } from "context/DialogContext";
import { Loading } from "pages/Loading";
import { fetchSettings } from "services/jornada.service";
import usePointsStore from "store";

let checkInterval: any;
const checkIntervalTime = 10000;

function Locked() {
	const navigate = useNavigate();
	const { reset } = useCoop();
	const { closeDialog } = useDialog();
	const { loadingSettings, isGameEnabled } = useAuth();

	const { resetPointsStore } = usePointsStore();

	useEffect(() => {
		reset();
		closeDialog();
		resetPointsStore();
	}, [reset, resetPointsStore]);

	useEffect(() => {
		if (isGameEnabled) {
			navigate("/");
		}
	}, [isGameEnabled]);

	useEffect(() => {
		if (!checkInterval) {
			checkInterval = setInterval(async () => {
				const settings = await fetchSettings();
				if (settings?.game_enabled) {
					clearInterval(checkInterval);
					checkInterval = null;
					navigate("/");
				}
			}, checkIntervalTime);
		}
		return () => {
			clearInterval(checkInterval);
			checkInterval = null;
		};
	}, []);

	if (loadingSettings) {
		return <Loading />;
	}

	return (
		<div
			id="home"
			className="cursor-pointer relative w-full h-full flex items-center justify-center"
		>
			<div className="flex flex-col items-center">
				<LogoJornada className="w-[380px] h-[380px] shadow-glow-blue" />
				<p className="text-white text-center text-3xl mt-10 font-bold">
					VOLTAREMOS JÁ
				</p>
			</div>
			<LogoOCB white className="absolute top-10 left-10" />
		</div>
	);
}

export default Locked;
