import bg from "assets/images/bg/bg-somos-coop.jpg";
import { useCallback, useEffect } from "react";
import usePointsStore from "store";

import logo from "assets/images/logo-avalia-desempenho.svg";
import AchievementModal from "components/modal/AchievementModal";

export default function DesempenhoGame() {
	const {
		points,
		pontosGameDesempenho,
		setPontosGameDesempenho,
		toggleNavigation,
	} = usePointsStore();

	const onMessage = useCallback(
		({ data }: any) => {
			if (data.type === "game-finished") {
				toggleNavigation(true);
				setPontosGameDesempenho(data.score);
			}
		},
		[toggleNavigation, points]
	);

	useEffect(() => {
		toggleNavigation(false);
		window.addEventListener("message", onMessage);
		return () => {
			window.removeEventListener("message", onMessage);
			toggleNavigation(true);
		};
	}, [toggleNavigation]);

	return (
		<>
			{pontosGameDesempenho ? (
				<AchievementModal
					positive
					points={pontosGameDesempenho}
					logo={logo}
					title={``}
				/>
			) : null}
			<div
				className="bg-black absolute top-0 left-0 w-full h-full"
				style={{
					backgroundImage: `url(${bg})`,
				}}
			>
				<div className="absolute flex flex-col p-5 gap-5 w-full h-full top-0 left-0 z-10 bg-blue-dark bg-opacity-90">
					<div className="mt-[100px] text-2xl text-white flex flex-col items-center">
						<div className="flex gap-10 items-center">
							<div className="text-center leading-tight text-3xl">
								Colete o máximo de moedas que conseguir
							</div>
						</div>
					</div>
					<div className="relative flex-1 bg-black rounded-xl overflow-hidden">
						<iframe
							src="/games/coins/index.html?game=desempenho"
							className="absolute w-full h-full"
						/>
					</div>
				</div>
			</div>
		</>
	);
}
