import { ReactNode, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useAuth } from "context/AuthContext";
import { useCoop } from "context/CoopContext";
import { formatCNPJ } from "services/utils";

interface IPrivateRoute {
	permissions?: string[];
	roles?: string[];
	redirectTo?: string;
	children: ReactNode;
}

export function PrivateRoute({
	permissions,
	roles,
	redirectTo = "/",
	children,
}: IPrivateRoute) {
	const { user, isAuthenticated, isGameEnabled, checkGameIsEnabled } =
		useAuth();
	const { coopInfo, loadCoopData } = useCoop();

	const [initialized, setInitialized] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!coopInfo && user?.extra.cnpj) {
			console.log("PrivateRoute useEffect");
			loadCoopData(formatCNPJ(user?.extra.cnpj));
		}
	}, [coopInfo, user]);

	useEffect(() => {
		if (!isGameEnabled && location.pathname !== "/locked") {
			navigate("/locked");
		} else if (!isAuthenticated) {
			return navigate(redirectTo);
		}
		setInitialized(true);
	}, [navigate]);

	if (!initialized) {
		return null;
	}

	if (isAuthenticated && !coopInfo) {
		return (
			<div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
				<h1 className="text-white text-4xl font-bold">Carregando...</h1>
			</div>
		);
	}

	return <>{children}</>;
}
