import AnimatedText from "components/ui/AnimatedText";
import Back from "components/ui/Back";
import { useAuth } from "context/AuthContext";
import { useCoop } from "context/CoopContext";
import { useCallback, useEffect, useState } from "react";
import { BiRocket, BiUser } from "react-icons/bi";
import { FaBackspace } from "react-icons/fa";
import OtpInput from "react-otp-input";
import useAuthStore from "store/auth";

const keys = [
	[1, 2, 3],
	[4, 5, 6],
	[7, 8, 9],
	["*", 0, -1],
];

type LoginFastPassProps = {
	onAuthSuccess: () => void;
};

function LoginFastPass({ onAuthSuccess }: LoginFastPassProps) {
	const [otp, setOtp] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const { login } = useAuth();
	const { setLoginType } = useAuthStore();

	const handleLogin = useCallback(async () => {
		try {
			setLoading(true);
			await login({
				username: otp,
				password: otp,
			});

			onAuthSuccess();
		} catch (error) {
			console.log(error);
			setError("Código de acesso inválido");

			setTimeout(() => {
				setLoading(false);
				setError("");
				setOtp("");
			}, 2000);
		}
	}, [otp, onAuthSuccess]);

	useEffect(() => {
		if (otp.length === 4 && !loading) {
			// onAuthSuccess();
			handleLogin();
		}
	}, [loading, otp, onAuthSuccess]);

	return (
		<>
			<Back
				className="absolute top-32 left-44"
				onClick={() => setLoginType("")}
			/>

			<AnimatedText>
				<h1 className="text-center text-blue-dark mb-2 text-7xl font-bold">
					ACESSO RÁPIDO
				</h1>
				<p className="text-center text-blue-dark text-5xl">
					Digite o código da fila
				</p>
			</AnimatedText>

			<div className="flex items-center justify-center p-10">
				<OtpInput
					value={otp}
					onChange={setOtp}
					numInputs={4}
					renderSeparator={<span>-</span>}
					renderInput={(props) => (
						<input
							{...props}
							className="rounded-xl border-4 border-blue-dark"
						/>
					)}
					inputStyle={{
						width: "110px",
						height: "160px",
						fontSize: "4rem",
						fontWeight: "bold",
						lineHeight: "100%",
						margin: "0 10px",
					}}
				/>
			</div>

			{!loading ? (
				<div className="flex-1 flex flex-col items-center">
					<div className="bg-blue-dark h-full rounded-xl p-8 gap-3 grid grid-col-3">
						{keys.map((row, i) => (
							<div key={i} className="flex justify-center gap-3">
								{row.map((key) => {
									if (key === "*") {
										return <div key={key} className="w-36"></div>;
									} else if (key === -1) {
										return (
											<button
												key={key}
												className="bg-red-500 shadow-red-300 shadow-inner inset-10 text-white rounded-xl w-36 h-20 text-5xl font-bold flex justify-center items-center"
												onClick={() => setOtp(otp.slice(0, -1))}
											>
												<FaBackspace />
											</button>
										);
									}
									return (
										<button
											key={key}
											className="bg-blue-500 shadow-blue-300 shadow-inner inset-10 text-white rounded-xl w-36 h-20 text-5xl font-bold"
											onClick={() => setOtp(otp + key)}
										>
											{key}
										</button>
									);
								})}
							</div>
						))}
					</div>
				</div>
			) : null}

			{loading ? (
				<div className="flex flex-col items-center">
					<div
						className={`rounded-xl p-8 gap-3 grid grid-col-3 ${
							error ? "bg-red-500 animate animate-wiggle" : "bg-blue-dark"
						}`}
					>
						<h1 className="text-3xl text-white uppercase font-bold">
							{error ? error : "CARREGANDO..."}
						</h1>
					</div>
				</div>
			) : null}
		</>
	);
}

export default LoginFastPass;
