import { useEffect } from "react";

import { useCoop } from "context/CoopContext";
import { useDialog } from "context/DialogContext";

import LogoJornada from "components/ui/LogoJornada";
import LogoOCB from "components/ui/LogoOCB";

import usePointsStore from "store";

export function Loading() {
	const { reset } = useCoop();
	const { closeDialog } = useDialog();
	const { resetPointsStore } = usePointsStore();

	useEffect(() => {
		reset();
		closeDialog();
		resetPointsStore();
	}, [reset, resetPointsStore, closeDialog]);

	return (
		<div
			id="home"
			className="cursor-pointer relative w-full h-full flex items-center justify-center"
		>
			<div className="flex flex-col items-center">
				<LogoJornada className="w-[380px] h-[380px] shadow-glow-blue" />
				<p className="text-white text-center text-3xl mt-10 font-bold">
					CARREGANDO...
				</p>
			</div>
			<LogoOCB white className="absolute top-10 left-10" />
		</div>
	);
}
